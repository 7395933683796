import { useTranslation } from 'next-i18next';

export type BreadcrumbsProps = {
  /** A list of breadcrumbs to display */
  breadcrumbs?: { name?: string | null; uri?: string | null }[];
} & React.HTMLAttributes<HTMLDivElement>;

export const Breadcrumbs = ({ breadcrumbs, ...rest }: BreadcrumbsProps) => {
  const { t } = useTranslation('osc-breadcrumbs');

  if (!breadcrumbs?.length) return null;

  return (
    <nav aria-label={t('breadcrumb')} data-testid="osc-breadcrumbs" {...rest}>
      <ol className="text-text-alt flex flex-wrap text-xs md:text-sm">
        {breadcrumbs.map((breadcrumb, idx) => {
          const isLastBreadcrumb = idx === breadcrumbs.length - 1;
          return (
            <li key={breadcrumb.uri}>
              {isLastBreadcrumb ? (
                <a aria-current="page" href={breadcrumb.uri || ''}>
                  {breadcrumb.name}
                </a>
              ) : (
                <>
                  <a className="underline" href={breadcrumb.uri || ''}>
                    {breadcrumb.name}
                  </a>
                  <span aria-hidden={true} className="mx-2">
                    /
                  </span>
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
