import type { PropsWithChildren, RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useDebounceCallback, useIntersectionObserver, useMediaQuery } from 'usehooks-ts';
import { useStickyLayout } from '../../hooks/use-sticky-layout';

export const SearchWrapperStickyOnScroll = ({
  children,
  childRef: shopFormRef,
}: PropsWithChildren & {
  childRef: RefObject<HTMLElement>;
}) => {
  const searchWrapperRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const stickyElemRef = isLargeScreen ? searchWrapperRef : shopFormRef;
  const isSearchWrapperSticky = useStickyLayout({
    ref: stickyElemRef,
  });
  const [isSearchVisibleOnScroll, setIsSearchVisibleOnScroll] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(document.scrollingElement?.scrollHeight ?? 0);
  const [isSearchContentHidden, setSearchContentHidden] = useState(false);
  const shopFormBoundingTop = shopFormRef?.current?.getBoundingClientRect().top ?? 0;
  const searchWrapperBoundingTop = searchWrapperRef?.current?.getBoundingClientRect().top ?? 0;
  const insetHeight = !isSearchVisibleOnScroll
    ? -2 * (searchWrapperRef.current?.getBoundingClientRect().height ?? 1)
    : 0;

  const stickyTopNegativeOffset = useMemo(() => {
    if (!isLargeScreen) {
      const wrapperTopNegativeOffset = isSearchContentHidden ? insetHeight : 0;
      return isSearchVisibleOnScroll
        ? -1 * (shopFormBoundingTop - searchWrapperBoundingTop)
        : wrapperTopNegativeOffset;
    }
    return 0;
  }, [
    insetHeight,
    isSearchContentHidden,
    isLargeScreen,
    isSearchVisibleOnScroll,
    searchWrapperBoundingTop,
    shopFormBoundingTop,
  ]);

  const { ref: inViewRef, isIntersecting } = useIntersectionObserver({
    threshold: 0,
  });

  const attachRefs = useCallback(
    (node: HTMLDivElement) => {
      searchWrapperRef.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  const handleScrolling = useCallback(() => {
    if (scrollHeight > window.scrollY) {
      if (isSearchWrapperSticky && !isIntersecting) {
        if (!isSearchContentHidden) {
          setSearchContentHidden(true);
        }
        setIsSearchVisibleOnScroll(isSearchWrapperSticky);
      }
    } else if (scrollHeight < window.scrollY && isSearchVisibleOnScroll && isSearchContentHidden) {
      setIsSearchVisibleOnScroll(false);
    }
    setScrollHeight(window.scrollY);
  }, [
    isSearchContentHidden,
    isIntersecting,
    isSearchVisibleOnScroll,
    isSearchWrapperSticky,
    scrollHeight,
  ]);

  useDebounceCallback(handleScrolling, 400);

  useEffect(() => {
    window.addEventListener('scroll', handleScrolling);
    return () => {
      window.removeEventListener('scroll', handleScrolling);
    };
  }, [handleScrolling]);

  return (
    <div
      ref={attachRefs}
      className={cx(
        'inset-0 sticky m-0 w-full flex-col flex-nowrap p-0 motion-reduce:transition-none transition-all duration-1000 ease-in-out',
        {
          'z-30': isSearchVisibleOnScroll,
          'z-10': !isSearchVisibleOnScroll,
        }
      )}
      style={{
        insetBlockStart: insetHeight,
        top: `${stickyTopNegativeOffset}px`,
      }}
    >
      {children}
    </div>
  );
};
