import { Footer } from '@dx-ui/osc-footer';
import { useRouter } from 'next/router';
import { HotelPage } from '../../helpers/constants';
import type { LayoutData } from '../../helpers/layout.types';
import { getHotelsRouteParams } from '../../helpers/routing';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { HeaderWrapper } from '../header';
import { SearchWrapper } from '../search/SearchWrapper';

type THotelLayoutProps = {
  children: React.ReactNode;
  layout: LayoutData;
  page: ValuesOf<typeof HotelPage>;
  ctyhocn: string;
  showNumAttendees?: boolean;
  traits?: string[];
};

const HotelLayout = ({
  children,
  ctyhocn,
  layout,
  page,
  showNumAttendees,
  traits = [],
}: THotelLayoutProps) => {
  const lang = useRouter().locale || 'en';
  const router = useRouter();
  const { wifi: hasJoinedPropertyWiFi } = getHotelsRouteParams(router);

  const {
    adultAge,
    adultsOnly,
    address,
    ageBasedPricing,
    brandCode: unsafeBrandCode,
    breadCrumbs,
    currencyCode,
    footerData,
    headerData,
    hotelName,
    isNewHotel,
    isPartnerBrand,
    maxNumRooms,
    maxOccupants,
    open,
    openDate,
    preOpenMsg,
    resEnabled,
    resEnabledDate,
    templateType,
    minArrivalDate,
    maxArrivalDate,
  } = layout;

  const brandCode = unsafeBrandCode ?? '';
  const isFromWiFi = hasJoinedPropertyWiFi && page === HotelPage.HOTELINFO;
  const shouldDisplayShopFormOnPage = page !== HotelPage.OFFERS && !isFromWiFi;

  const searchWrapperData = {
    brandCode,
    ctyhocn,
    lang,
    resEnabled,
    open,
    openDate,
    preOpenMsg,
    resEnabledDate,
    hotelName,
    address,
    isFromWiFi,
    isNewHotel,
    traits,
    templateType,
    shouldDisplayShopFormOnPage,
    searchWidgetProps: {
      adultAge,
      ageBasedPricing,
      adultsOnly,
      currencyCode,
      isPartnerBrand,
      showNumAttendees,
      maxNumRooms,
      maxOccupants,
      isGroupSearch: page === HotelPage.MEETINGSEVENTS,
      hideFlexDates: page === HotelPage.MEETINGSEVENTS,
      minArrivalDate,
      maxArrivalDate,
    },
  };
  return (
    <>
      <HeaderWrapper {...headerData} brandCode={brandCode} ctyhocn={ctyhocn} lang={lang} />
      <SearchWrapper {...searchWrapperData} />
      {children}
      {breadCrumbs ? <BreadCrumbs breadCrumbs={breadCrumbs} /> : null}
      <Footer {...footerData} />
    </>
  );
};

export default HotelLayout;
