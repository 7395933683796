import type * as React from 'react';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';
import type { IconProps } from '@dx-ui/osc-icon';

export type BannerNotificationProps = {
  /** Content to be displayed in banner notification. */
  children: React.ReactNode;
  /** The status to be use. */
  status: 'error' | 'success' | 'normal';
  customIcon?: IconProps;
} & React.HTMLAttributes<HTMLDivElement>;

const STATUS_ROLE_MAPPER = Object.freeze({
  error: 'assertive',
  success: 'polite',
  normal: 'polite',
});

/**
 * Displays a banner notification message with an icon.
 */
export const BannerNotification = ({
  status,
  children,
  className,
  customIcon,
  ...rest
}: BannerNotificationProps) => {
  return (
    <div
      className={cx(
        'flex w-full items-center gap-x-2 p-4 sm:px-6',
        {
          'bg-danger-alt text-danger': status === 'error',
          'bg-success-alt text-success': status === 'success',
          'bg-bg-alt text-bg-inverse': status === 'normal',
        },
        className
      )}
      aria-live={STATUS_ROLE_MAPPER[status]}
      aria-atomic={true}
      data-testid="bannerNotification"
      {...rest}
    >
      {customIcon ? (
        <Icon {...customIcon} />
      ) : (
        <>
          {status === 'error' && <Icon name="close-circle" variant="solid" />}
          {status === 'success' && <Icon name="check-circle" variant="solid" />}
        </>
      )}
      {children}
    </div>
  );
};
