export const LANGUAGE_CODE_NAMES = {
  ar: 'العربية',
  bg: 'Български',
  cs: 'Česky',
  da: 'Dansk',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fi: 'Suomi',
  fr: 'Français',
  id: 'Bahasa Indonesia',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'Pусский',
  sv: 'Svenska',
  th: 'ไทย',
  tr: 'Türkçe',
  'zh-hans': '简体中文',
  'zh-hant': '繁體中文',
} as const;
