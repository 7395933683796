export const AutoComplete = {
  ADDRESSLINE1: 'address-line1',
  ADDRESSLINE2: 'address-line2',
  CITY: 'address-level2',
  COUNTRY: 'country',
  CURRENTPASSWORD: 'current-password',
  EMAIL: 'email',
  FAMILYNAME: 'family-name',
  GIVENNAME: 'given-name',
  NAME: 'name',
  NEWPASSWORD: 'new-password',
  OFF: 'off',
  POSTALCODE: 'postal-code',
  STATE: 'address-level1',
  STREETADDRESS: 'street-address',
  TEL: 'tel',
  TELNATIONAL: 'tel-national',
  USERNAME: 'username',
  CREDITCARD: 'cc-number',
} as const;
