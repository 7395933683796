import { useWatch } from 'react-hook-form';
import { LocationInput } from './location.input';
import type { PredictionService } from '@dx-ui/framework-places-autocomplete';
import { usePlacesAutocomplete } from '@dx-ui/framework-places-autocomplete';
import { useDebounceValue } from 'usehooks-ts';
import { useGetTranslateAutocompleteConfigQuery } from './gql/queries';

export type Location = Omit<LocationInput, 'predictions' | 'status'> & {
  autocompleteUri?: PredictionService['uri'];
  type?: PredictionService['type'];
  count?: PredictionService['count'];
  enableRecentSearches?: boolean;
  assetsUrl?: string;
  applyExclusions?: boolean;
};

/**
 * Location is an optional component that may be consumed either standalone or as part of the newer [composable shop form component](?path=/docs/library-products-shop-form--default-story).
 *
 * > Note: The storybook plugin `react-docgen-typescript` has a bug generating props when `Omit` is used. @see https://github.com/styleguidist/react-docgen-typescript/issues/335
 */
export const Location: React.FC<React.PropsWithChildren<Location>> = ({
  coordinate,
  type,
  count,
  sessionId,
  label,
  language,
  applyExclusions,
  name = 'query',
  autocompleteUri = '/dx-customer/autocomplete',
  autoCompleteLength = 2,
  ...rest
}) => {
  const inputValue = useWatch({ name });

  const [debouncedInputValue] = useDebounceValue<string>(inputValue, 300);
  const enablePlacesAutoComplete =
    !!debouncedInputValue && debouncedInputValue.length >= autoCompleteLength;
  const { data, isFetched: IsTranslateAutocompleteResolved } =
    useGetTranslateAutocompleteConfigQuery();
  const configLanguages = data?.featureConfigs?.[0]?.config?.languages;
  const isSupportedLanguage =
    IsTranslateAutocompleteResolved &&
    !!configLanguages?.length &&
    configLanguages.includes(language);

  const { loading, predictions, status } = usePlacesAutocomplete({
    isEnabled: enablePlacesAutoComplete && IsTranslateAutocompleteResolved,
    minLength: autoCompleteLength,
    serviceOptions: {
      coordinate,
      uri: autocompleteUri,
      input: debouncedInputValue,
      type,
      applyExclusions,
      count,
      language: isSupportedLanguage ? language : 'en',
      sessionId,
    },
  });

  return (
    <LocationInput
      coordinate={coordinate}
      name={name}
      predictions={predictions}
      loading={loading}
      status={status}
      label={label}
      language={language}
      autoCompleteLength={autoCompleteLength}
      sessionId={sessionId}
      {...rest}
    />
  );
};

Location.displayName = 'Location';
export default Location;
