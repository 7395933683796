import type { PropsWithChildren, RefObject } from 'react';
import cx from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStickyLayout } from '../../hooks/use-sticky-layout';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

export const StickySearchWrapper = ({
  children,
  childRef: shopFormRef,
}: PropsWithChildren & {
  childRef: RefObject<HTMLElement>;
}) => {
  const searchElemRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const isWrapperSticky = useStickyLayout({ ref: isLargeScreen ? searchElemRef : shopFormRef });
  const [isSearchWrapperSticky, setSearchWrapperSticky] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(document.scrollingElement?.scrollHeight ?? 0);
  const [isSearchContentHidden, setSearchContentHidden] = useState(false);
  const shopFormBoundingTop = shopFormRef?.current?.getBoundingClientRect().top ?? 0;
  const searchWrapperBoundingTop = searchElemRef?.current?.getBoundingClientRect().top ?? 0;

  const stickyTopOffset = useMemo(() => {
    if (!isLargeScreen) {
      return isSearchWrapperSticky && isSearchContentHidden
        ? -1 * (shopFormBoundingTop - searchWrapperBoundingTop)
        : 0;
    }
  }, [
    isLargeScreen,
    isSearchContentHidden,
    isSearchWrapperSticky,
    searchWrapperBoundingTop,
    shopFormBoundingTop,
  ]);

  useEffect(() => {
    setSearchWrapperSticky(isWrapperSticky);
  }, [isWrapperSticky]);

  const handleScrolling = useCallback(() => {
    if (isLargeScreen) {
      return false;
    }
    if (isSearchWrapperSticky) {
      if (window.scrollY > scrollHeight) {
        if (!isSearchContentHidden) {
          setSearchContentHidden(true);
        }
      } else if (window.scrollY < scrollHeight) {
        if (window.scrollY <= shopFormBoundingTop && isSearchContentHidden) {
          setSearchContentHidden(false);
        }
      }
      setScrollHeight(window.scrollY);
    }
  }, [
    isLargeScreen,
    isSearchWrapperSticky,
    scrollHeight,
    isSearchContentHidden,
    shopFormBoundingTop,
  ]);

  useDebounceCallback(handleScrolling, 700);

  useEffect(() => {
    window.addEventListener('scroll', handleScrolling);
    return () => {
      window.removeEventListener('scroll', handleScrolling);
    };
  }, [handleScrolling]);

  return (
    <div
      ref={searchElemRef}
      className={cx('inset-0 m-0 w-full flex-col flex-nowrap p-0', {
        'sticky z-30 motion-reduce:transition-none transition-all duration-[0.6s] ease-in-out':
          isSearchWrapperSticky,
        'z-0': !isSearchWrapperSticky,
      })}
      style={{
        top: `${stickyTopOffset}px`,
      }}
    >
      {children}
    </div>
  );
};
