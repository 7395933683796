/* eslint-disable jsx-a11y/anchor-is-valid */
import type * as React from 'react';
import type { MarkdownToJSX } from 'markdown-to-jsx';
import MarkdownToJsx from 'markdown-to-jsx';
import type { Rule } from '@dx-ui/utilities-url-config';
import { getUrlConfig, RuleEngine } from '@dx-ui/utilities-url-config';
import { Link } from '@dx-ui/osc-link';
import merge from 'lodash/merge';
import { Speedbump, useSpeedbump } from '@dx-ui/osc-speedbump';

export type Markdown = {
  /** Specific config rule that is used by the [RuleEngine](https://jira.hilton.com/stash/projects/HIW/repos/dx-shared/browse/packages/rule-engine) to process urls in the markdown */
  rule?: Rule;
  /** current language */
  language: string;
  /** url origin to use for determining url props to use when constructing links */
  origin: string;
} & MarkdownAttributes;

type MarkdownAttributes = {
  [key: string]: unknown;
  children: string;
  options?: MarkdownToJSX.Options;
};

type MarkdownLink = {
  language: string;
  origin: string;
  rule: Rule;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const MarkdownLink: React.FC<React.PropsWithChildren<MarkdownLink>> = ({
  children,
  rule,
  language,
  origin,
  href,
  ...props
}) => {
  const { onShow, onHide, isVisible, onContinue, isEnabled } = useSpeedbump(language);
  if (!href) {
    return null;
  }
  const linkProps: Link = { url: href };
  if (rule) {
    const ruleEngine = new RuleEngine(rule);
    const data = getUrlConfig(ruleEngine, href, origin, language);
    linkProps.url = data.url;
    if (data.speedbump) {
      linkProps.onClick = onShow;
    }
  }
  return (
    <>
      <Link {...linkProps} {...props}>
        {children}
      </Link>
      {isEnabled && <Speedbump isShowing={isVisible} onContinue={onContinue} onClose={onHide} />}
    </>
  );
};

/**
 * Used to display markdown content. This is a wrapper around [markdown-to-jsx](https://www.npmjs.com/package/markdown-to-jsx) that provides standard overrides
 * to handle links in the markdown. This incorporates the @see RuleEngine to process the urls in the markdown and provide
 * information to construct the [HTMLAnchorElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement).
 * @returns JSX that is used to render the html
 */
export const Markdown: React.FC<Markdown> = ({
  children,
  options,
  language,
  origin,
  rule,
  ...props
}) => {
  const defaultOptions = {
    overrides: {
      a: {
        component: MarkdownLink,
        props: {
          rule,
          language,
          origin,
          className: '!text-base',
        },
      },
    },
  };
  const mergedOptions = merge(defaultOptions, options);
  return (
    <MarkdownToJsx options={mergedOptions} {...props}>
      {children}
    </MarkdownToJsx>
  );
};

export default Markdown;
