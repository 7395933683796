import { sendReward } from '@dx-ui/framework-conductrics';
import set from 'lodash/set';

import { useAuth } from '@dx-ui/framework-auth-provider';

import {
  useSmb_MemberIdAndStatusQuery,
  useSmb_ProgramAccountMemberQuery,
} from '../lib/gql/queries';
import { env } from '@dx-ui/framework-env';
import type { UserLinks } from './types';
import { useRouter } from 'next/router';

const navigationInteractionGoal = 'g-6gRqwMh3Pt';

export const sendInteractionReward = () => {
  sendReward(navigationInteractionGoal);
};

export function removeSpacesAndAmpersands(str: string) {
  return str.replace(/\s+|&/g, '').toLowerCase();
}

export function trackNavClickBasedOnLabel(label: string, dropdownLabel?: string) {
  const value = removeSpacesAndAmpersands(`gh_${dropdownLabel ? `${dropdownLabel}_` : ''}${label}`);

  trackNavClick(value);
}

export function trackNavClick(value: string) {
  if (window?.digitalData && window?._satellite && value) {
    set(window.digitalData, 'click.clickID', value);
    window._satellite?.track?.('global_click');
  }
}

const replaceLang = (stringValue: string, lang: string) => stringValue?.replace('__LANG__', lang);

const DEFAULT_OCODE = 'JHTNW';

/** Returns the full set of potential nav links, including optional links like SMB and Go Hilton */
export function getBaseNavLinksFromEnv(lang: string, isAuthenticated: boolean) {
  const signInUrl = replaceLang(
    env('DX_AUTH_UI', 'https://www.hilton.com/__LANG__/auth2/guest/login/'),
    lang
  );
  const signUpUrl = replaceLang(
    env('HONORS_JOIN_URL', 'https://www.hilton.com/en/hilton-honors/join/') +
      `?ocode=${DEFAULT_OCODE}`,
    lang
  );

  const environmentBaseUrl = env('OHW_BASE_URL', 'https://www.hilton.com/');

  return {
    signInLink: { url: signInUrl },
    signUpLink: { url: signUpUrl },
    accountLink: { url: `${environmentBaseUrl}${lang}/hilton-honors/guest/my-account/` },
    honorsActivityLink: { url: `${environmentBaseUrl}${lang}/hilton-honors/guest/activity/` },
    smbMemberLink: { url: `${environmentBaseUrl}${lang}/business/manage/` },
    honorsPointsLink: { url: `${environmentBaseUrl}${lang}/hilton-honors/guest/points/` },
    honorsProfileLink: { url: `${environmentBaseUrl}${lang}/hilton-honors/guest/profile/` },
    friendsAndFamilyLink: {
      url: env('TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK', 'https://tmtp.hilton.com/tmtp/main.html'),
    },
    hgvMaxLink: {
      url: env(
        'EXTERNAL_HGV_PROGRAM_DETAILS_URL',
        'https://help.hilton.com/s/article/HGV-Max-rate'
      ),
    },
    goHiltonLink: {
      url: env(
        'EXTERNAL_TMTP_PROGRAM_DETAILS_URL',
        'https://help.hilton.com/s/article/What-is-the-Go-Hilton-employee-program'
      ),
    },
    findStayLink: {
      url: isAuthenticated
        ? `${environmentBaseUrl}${lang}/hilton-honors/guest/activity/`
        : `${environmentBaseUrl}${lang}/book/reservation/find/`,
    },
  } as const satisfies UserLinks;
}

export function useProgramMember({
  programAccountId,
  shouldFetchSmbMemberIdAndStatus = false,
}: Partial<{
  programAccountId: number | null | undefined;
  shouldFetchSmbMemberIdAndStatus: boolean;
}> = {}) {
  const { guestId, guestInfo } = useAuth();
  const { locale = 'en', query } = useRouter();

  const programAccountIdParam = isNaN(Number(query?.programAccountId))
    ? undefined
    : Number(query?.programAccountId);

  const accountId = programAccountId || programAccountIdParam;

  const { data, isLoading } = useSmb_MemberIdAndStatusQuery(
    { guestId: guestId as number, language: locale },
    {
      enabled: !!guestId && (!!guestInfo?.hhonors?.isSMBMember || shouldFetchSmbMemberIdAndStatus),
    }
  );

  let mostRelevantProgramAccount = data?.programAccounts?.[0];

  if ((data?.programAccounts?.length || 0) > 1 && accountId) {
    mostRelevantProgramAccount = data?.programAccounts.filter(
      (programAccount) => programAccount?.accountId === accountId
    )?.[0];
  }

  const { data: programAccountData, isLoading: memberDataLoading } =
    useSmb_ProgramAccountMemberQuery(
      {
        guestId: guestId as number,
        language: locale,
        input: {
          accountId: accountId || (mostRelevantProgramAccount?.accountId as number),
          memberId: mostRelevantProgramAccount?.memberId as number,
        },
      },
      {
        enabled:
          !!guestId &&
          !!mostRelevantProgramAccount?.memberId &&
          Boolean(accountId || mostRelevantProgramAccount?.accountId),
      }
    );

  const member = {
    accountId,
    ...programAccountData?.programAccountMember,
    memberDataLoading,
    isLoading,
  };
  return member;
}
