import { Markdown } from '@dx-ui/osc-markdown';
import type { Brand_CountriesQuery } from './queries/generated/types';
import { Link } from '@dx-ui/osc-link';
import { FormInput } from '@dx-ui/osc-form';
import { useMemo } from 'react';
import type { ReactNode } from 'react';

const Paragraph = ({ children }: { children: ReactNode }) => <p className="mb-4">{children}</p>;
const WrappedLink = ({ href, ...rest }: { href: string }) => <Link url={href} {...rest} />;
const CustomerExternalParagraph = ({ children }: { children: ReactNode }) => (
  <p className="!mt-0 ml-8 text-sm">{children}</p>
);

export const MarketingContent = ({
  selectedCountry,
  setMarketingOptinsSelected,
  language,
  origin,
}: {
  selectedCountry: NonNullable<Brand_CountriesQuery>['countries'][0];
  setMarketingOptinsSelected: React.Dispatch<React.SetStateAction<number[]>>;
  language: string;
  origin: string;
}) => {
  const marketingOptin = selectedCountry?.marketingOptin;
  const rules = marketingOptin?.marketingOptinRules[0];
  const optIns = useMemo(
    () => rules?.optIns.filter((optIn) => !optIn?.autoOptIn) || [],
    [rules?.optIns]
  );
  const termsAndConditions = useMemo(
    () =>
      rules?.optIns.find((optIn) => optIn?.termsAndConditionsJoin)?.termsAndConditionsJoin ?? '',
    [rules?.optIns]
  );

  const handleMarketingOptinChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setMarketingOptinsSelected((prevSelected) => {
      const isChecked = event.target?.checked;
      if (isChecked) {
        return [...prevSelected, index];
      } else {
        return prevSelected.filter((i) => i !== index);
      }
    });
  };

  return (
    <>
      {optIns &&
        optIns.map((optIn, index) => {
          const { autoOptIn, customerTextExternal, name } = optIn;
          if (autoOptIn) {
            return null;
          }
          return (
            <section key={optIn._id}>
              <FormInput
                name={`marketing.${index}`}
                label={name}
                type="checkbox"
                labelClassName="w-full"
                optional={true}
                aria-describedby={`marketing-${index}`}
                registerOptions={{
                  onChange: (event) => handleMarketingOptinChange(event, index),
                }}
              />
              {customerTextExternal && (
                <Markdown
                  language={language}
                  origin={origin}
                  options={{
                    forceBlock: true,
                    overrides: {
                      p: CustomerExternalParagraph,
                      a: {
                        component: WrappedLink,
                        props: {
                          className: 'underline text-primary hover:text-primary-alt text-base',
                          isNewWindow: true,
                        },
                      },
                    },
                  }}
                >
                  {customerTextExternal}
                </Markdown>
              )}
            </section>
          );
        })}
      <Markdown
        origin={origin}
        language={language}
        options={{
          forceBlock: true,
          overrides: {
            p: Paragraph,
            a: {
              component: WrappedLink,
              props: {
                className: 'underline text-primary hover:text-primary-alt text-base',
                isNewWindow: true,
              },
            },
          },
        }}
      >
        {termsAndConditions}
      </Markdown>
    </>
  );
};
