import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { ActionDialog, DialogTitle } from '@dx-ui/osc-dialog-v2';
import set from 'lodash/set';

export type SpeedbumpProps = {
  /**
   * displays the speeedbump or not
   */
  isShowing: boolean;
  /**
   * handler to close the speedbump
   */
  onClose: () => void;
  /**
   * handler used to proceed if chosen
   */
  onContinue: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
};

/**
 * Used to display a speedbump when transitioning to a non translated page
 */
export const Speedbump: React.FC<React.PropsWithChildren<SpeedbumpProps>> = ({
  isShowing,
  onClose: onCloseProp,
  onContinue: onContinueProp,
}) => {
  const [t] = useTranslation('osc-speedbump');
  const handleConfirm = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (window?.digitalData && window?._satellite) {
      set(window.digitalData, 'click.clickID', 'speedbump_continue');
      window._satellite?.track?.('global_click');
    }
    onContinueProp?.(e);
  };
  const handleCancel = () => {
    if (window?.digitalData && window?._satellite) {
      set(window.digitalData, 'click.clickID', 'speedbump_return');
      window._satellite?.track?.('global_click');
    }
    onCloseProp?.();
  };

  const handleDismiss = () => {
    onCloseProp?.();
  };

  return (
    <ActionDialog
      buttonOptions={{ cancel: { label: t('back') }, confirm: { label: t('continue') } }}
      isOpen={isShowing}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onDismiss={handleDismiss}
      size="sm"
      hasChildrenWithTitle={true}
    >
      <div data-testid="speedbumpDialog" className="mt-3">
        <DialogTitle className="mb-4 text-center text-xl font-bold">{t('title')}</DialogTitle>
      </div>
    </ActionDialog>
  );
};

export default Speedbump;
