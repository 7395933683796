import * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import type { DialogBaseProps, DialogOptionalProps } from './dialog.base';

export type DialogAlertProps = Omit<DialogBaseProps, 'onDismiss' | 'onCancel'> & {
  /**
   * The text of the cancel button
   */
  cancel?: string | null;
  /**
   * The text of the confirm button
   */
  confirm?: string | null;
  /** The element that opens the dialog that focus should return to */
  dialogTrigger?: React.ReactNode;
  /**
   * Callback for when the cancel button is clicked.
   * If this is not provided, the cancel button will not show.
   */
  onCancel?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  /**
   * Callback for when the confirm button is clicked.
   */
  onConfirm: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  /**
   * Whether or not to stack the buttons vertically
   */
  stackedButtons?: boolean;
} & DialogOptionalProps;

/**
 * A dialog meant for a user to confirm an action. Escape will not close this dialog.
 */
const DialogAlert: React.FC<React.PropsWithChildren<DialogAlertProps>> = ({
  ariaLabel,
  children,
  cancel,
  confirm,
  dialogTrigger,
  isOpen,
  onCancel,
  onConfirm,
  stackedButtons = true,
  title,
  ...rest
}) => {
  const { t } = useTranslation('osc-dialog');
  const dialogRef = React.useRef<React.ElementRef<'div'>>(null);
  const cancelText = cancel ?? t('cancel');
  const confirmText = confirm ?? t('ok');

  const handleCancel = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    onCancel?.(event);
  };

  const handleConfirm = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    onConfirm(event);
  };

  return (
    <AlertDialog.Root open={isOpen}>
      {dialogTrigger ? <AlertDialog.Trigger asChild>{dialogTrigger}</AlertDialog.Trigger> : null}
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="dialog-overlay sm:py-4 lg:py-12">
          <AlertDialog.Content
            className={cx('dialog-content-v2 max-w-sm rounded-lg md:max-h-[90vh]', {
              'flex flex-col': isOpen,
            })}
            {...rest}
            onEscapeKeyDown={(event) => event.preventDefault()}
            ref={dialogRef}
          >
            <div className="text-center">
              {title ? (
                <AlertDialog.Title className="mb-3 text-xl font-bold leading-tight">
                  {title}
                </AlertDialog.Title>
              ) : (
                <AlertDialog.Title aria-label={ariaLabel} className="hidden" />
              )}
              <AlertDialog.Description>{children}</AlertDialog.Description>
            </div>
            <div
              className={cx('flex items-center justify-center pt-4 gap-2', {
                'flex-col': stackedButtons,
              })}
            >
              {!onCancel ? (
                <AlertDialog.Cancel asChild>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg w-full"
                    onClick={handleConfirm}
                  >
                    {confirmText}
                  </button>
                </AlertDialog.Cancel>
              ) : (
                <AlertDialog.Action asChild>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg w-full"
                    onClick={handleConfirm}
                  >
                    {confirmText}
                  </button>
                </AlertDialog.Action>
              )}
              {onCancel ? (
                <AlertDialog.Cancel asChild>
                  <button
                    type="button"
                    className="btn btn-primary-outline btn-lg w-full"
                    onClick={handleCancel}
                  >
                    {cancelText}
                  </button>
                </AlertDialog.Cancel>
              ) : null}
            </div>
          </AlertDialog.Content>
        </AlertDialog.Overlay>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export { DialogAlert };
export default DialogAlert;
