import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useWatch } from 'react-hook-form';
import SearchButton from './shop-form.button';
import { ActionDialog } from '@dx-ui/osc-dialog';
import { Stepper } from '@dx-ui/osc-stepper';
import type { FormDataValues } from '..';
import { addDays, subDays } from 'date-fns';
import { useShopFormContext } from './use-shop-form-context';

type ShopFormNightsProps = {
  min?: number;
  max?: number;
};

/**
 * Use the `<ShopFormNights/>` component inside of the ShopForm in order to provide the OSC experience
 * for selecting the number of nights to enhance the search.
 */
const ShopFormNights: React.FC<React.PropsWithChildren<ShopFormNightsProps>> = ({
  min = 0,
  max = 30,
}) => {
  const [t] = useTranslation('osc-rooms');
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const {
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useShopFormContext();
  const numNights = useWatch<FormDataValues, 'numNights'>({ name: 'numNights' });
  const departureDate = useWatch<FormDataValues, 'dates.departureDate'>({
    name: 'dates.departureDate',
  });
  const [initialNumNights, setInitialNumNights] = React.useState<FormDataValues['numNights']>();
  const [initialDepartureDate, setInitialDepatureDate] =
    React.useState<FormDataValues['dates']['departureDate']>();
  const openModal = () => {
    setInitialNumNights(numNights);
    setInitialDepatureDate(departureDate);
    setOpen(true);
  };

  const confirmAndCloseModal = () => {
    setOpen(false);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };
  const dismissModal = () => {
    setOpen(false);
    if (initialNumNights && initialDepartureDate) {
      const formData = getValues();
      reset(
        {
          ...formData,
          numNights: initialNumNights,
          dates: { ...formData.dates, departureDate: initialDepartureDate },
        },
        { keepDefaultValues: true }
      );
    }
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  if (numNights === null || !departureDate) {
    return null;
  }

  const label =
    numNights === 0
      ? t('nights.dayCount', { count: 1 })
      : t('nights.nightsCount', { count: numNights });
  const dialogTitle = t('nights.howManyNights');

  return (
    <SearchButton
      product="search-nights-button"
      ref={buttonRef}
      onClick={openModal}
      hasError={Boolean(errors.numNights)}
      label={label}
      aria-label={label}
      data-testid="search-nights-button"
    >
      <ActionDialog
        className="flex flex-col overflow-auto"
        contentClassName="h-auto"
        ariaLabel={dialogTitle}
        title={dialogTitle}
        isOpen={open}
        onDismiss={dismissModal}
        onConfirm={confirmAndCloseModal}
        size="sm"
      >
        <Stepper
          onMinusClick={() => {
            setValue('numNights', numNights - 1, { shouldDirty: true });
            setValue('dates.departureDate', subDays(departureDate, 1), {
              shouldDirty: true,
            });
          }}
          onPlusClick={() => {
            setValue('numNights', numNights + 1, { shouldDirty: true });
            setValue('dates.departureDate', addDays(departureDate, 1), {
              shouldDirty: true,
            });
          }}
          min={min}
          max={max}
          value={numNights}
          valueLabel={label}
          plusLabel={t('nights.addNight')}
          plusTestId="search-add-night"
          minusLabel={t('nights.removeNight')}
          minusTestId="search-remove-night"
          className="pb-2.5"
        />
      </ActionDialog>
    </SearchButton>
  );
};

export { ShopFormNights };
export default ShopFormNights;
