import { useTranslation } from 'next-i18next';
import type { TFunction } from 'i18next';
import { differenceInDays, isSameYear } from 'date-fns';
import type { ShopFormRoom } from '@dx-ui/osc-rooms';

type ResSummaryText = {
  dates: string;
  adaDates: string;
  kidsAgesText: string | null;
  occupancy: string;
  stayDuration?: string;
};

const handleUnder1Text = (childAge: number, t: TFunction<'osc-rooms', undefined>) =>
  childAge === 0 ? t('occupancy.under1') : childAge;

export const useShopFormSummaryText = ({
  arrivalDate,
  departureDate,
  language,
  showChildAges,
  rooms,
}: {
  arrivalDate: Date | null;
  departureDate: Date | null;
  /** language value passed from router */
  language: string;
  showChildAges?: boolean;
  rooms: ShopFormRoom[];
}): ResSummaryText => {
  const { t } = useTranslation('osc-rooms');
  const { formattedStayDates, adaFormattedStayDates, stayDuration } = useFormattedStayDates({
    arrivalDate,
    departureDate,
    language,
  });
  const numRooms = rooms.length;
  const { numAdults, numChildren, childrenAges } = rooms.reduce(
    (acc, room) => {
      acc.numAdults = acc.numAdults + room.adults;
      acc.numChildren = acc.numChildren + room.children.length;
      acc.childrenAges = [
        ...acc.childrenAges,
        ...room.children.filter((c) => typeof c.age === 'number').map((c) => c.age as number),
      ];
      return acc;
    },
    { numAdults: 0, numChildren: 0, childrenAges: [] as number[] }
  );
  const updatedChildrenAges = childrenAges?.map((childAge) => handleUnder1Text(childAge, t));
  const roomsText = t('occupancy.rooms', { count: numRooms });
  const adultsText = t('occupancy.adult', { count: numAdults ?? undefined });
  const hasKids = (numChildren || 0) > 0;
  const kidsText = hasKids ? t('occupancy.kids', { count: numChildren ?? undefined }) : '';

  let kidsAgesText: string | null = null;

  if (showChildAges && hasKids && updatedChildrenAges?.length) {
    const firstKidsAge = updatedChildrenAges?.slice(0, -1).join(', ');

    kidsAgesText = t('occupancy.kidsAgeText', {
      count: updatedChildrenAges.length,
      firstKidsAge: updatedChildrenAges.length < 3 ? firstKidsAge : firstKidsAge + ',',
      lastKidAge: updatedChildrenAges?.slice(-1)[0],
      kidAge: updatedChildrenAges[0],
    });
  }

  const noDatesStayDuration = formattedStayDates ? t('occupancy.dayOnly') : t('occupancy.noDates');

  return {
    dates: formattedStayDates,
    adaDates: adaFormattedStayDates,
    kidsAgesText,
    occupancy: t(kidsText ? 'occupancy.occupancyWithKids' : 'occupancy.occupancy', {
      adults: adultsText,
      kids: kidsText,
      rooms: roomsText,
    }),
    stayDuration:
      stayDuration === 0
        ? noDatesStayDuration
        : t('occupancy.stayDurationNights', { count: stayDuration }),
  };
};

export const formatDates = (arrivalDate: Date, departureDate: Date, locale: Intl.Locale) => {
  const sameYear = isSameYear(arrivalDate, departureDate);
  const kanji = isKanji(locale);
  const month = isTurkish(locale) ? ('long' as const) : ('short' as const);
  const weekday = 'short';
  const day = 'numeric';
  const year = 'numeric';
  const options: Intl.DateTimeFormatOptions = {
    weekday,
    month,
    day,
  };
  const optionsWithYear: Intl.DateTimeFormatOptions = { ...options, year };

  const arrivalDateString = arrivalDate.toLocaleDateString(
    locale,
    !sameYear || kanji ? optionsWithYear : options
  );
  const departureDateString = departureDate.toLocaleDateString(
    locale,
    sameYear && kanji ? options : optionsWithYear
  );

  return `${arrivalDateString} \u2013 ${departureDateString}`;
};

export interface Props {
  children: (args: {
    arrivalDate: Date | null;
    departureDate: Date | null;
    formattedStayDates: string;
    adaFormattedStayDates: string;
    stayDuration: number;
  }) => JSX.Element | null;
}

export function useFormattedStayDates({
  language,
  arrivalDate,
  departureDate,
}: {
  language: string;
  arrivalDate: Date | null;
  departureDate: Date | null;
}) {
  const locale = useLocale({ language });
  const { t, ready: textReady } = useTranslation('osc-rooms');

  return {
    formattedStayDates:
      !!arrivalDate && !!departureDate && !!locale
        ? formatDates(arrivalDate, departureDate, locale)
        : '',
    adaFormattedStayDates:
      !!arrivalDate && !!departureDate && !!locale
        ? t('occupancy.stayDatesADA', {
            arrivalDate: arrivalDate.toLocaleDateString(locale, {
              dateStyle: 'full',
            }),
            departureDate: departureDate.toLocaleDateString(locale, { dateStyle: 'full' }),
          })
        : '',
    stayDuration:
      !!arrivalDate && !!departureDate ? differenceInDays(departureDate, arrivalDate) : 0,
    textReady,
  };
}

export const isKanji = (locale: string | Intl.Locale) => {
  const language = localeToLanguage(locale);
  return ['ja', 'ko', 'zh-hans', 'zh-hant'].includes(language);
};

export const isTurkish = (locale: Intl.Locale | string) => {
  const language = localeToLanguage(locale);
  return ['tr'].includes(language);
};

function localeToLanguage(locale: string | Intl.Locale) {
  return (
    isLocale(locale) && locale?.baseName ? locale.baseName : (locale as string)
  ).toLowerCase();
}

function isLocale(locale: string | Intl.Locale | undefined): locale is Intl.Locale {
  if ((locale as Intl.Locale)?.baseName) {
    return true;
  }
  return false;
}

const defaultOptions: Intl.DateTimeFormatOptions = {
  calendar: 'gregory',
};

const localeOptions: Record<string, Intl.DateTimeFormatOptions> = {
  ar: {
    numberingSystem: 'latn',
  },
};

export function useLocale({ language }: { language: string }) {
  return new Intl.Locale(language, {
    ...defaultOptions,
    ...localeOptions[language],
  });
}
