import { Dialog } from '@dx-ui/osc-dialog';
import { useTranslation } from 'next-i18next';

export const GeocodeFailModal = ({
  isGeocodeError,
  setIsGeoCodeError,
}: {
  isGeocodeError: boolean;
  setIsGeoCodeError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('osc-shop-form');

  return (
    <Dialog
      ariaLabel={t('geocodeFailModalTitle')}
      isOpen={isGeocodeError}
      size="sm"
      onDismiss={() => setIsGeoCodeError(false)}
      title={t('geocodeFailModalTitle')}
    >
      <div className="pb-2 text-center">
        <div>{t('geocodeFailModalContent')}</div>
      </div>
      <div className="flex items-center justify-center space-x-2 py-2 rtl:space-x-reverse">
        <button
          className="btn btn-lg btn-primary"
          onClick={() => setIsGeoCodeError(false)}
          type="button"
        >
          {t('geocodeFailModalButton')}
        </button>
      </div>
    </Dialog>
  );
};
