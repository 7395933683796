import { addDays, startOfWeek } from 'date-fns';
import { useMemo } from 'react';
import { isArabic, weekStartsOn } from './utils';

export type UseDaysOfWeek = {
  /** The locale used to get the in-language days of the week. */
  locale: Intl.Locale;
  /** The option to use when formatting the days of the wekk. @see Intl.DateTimeFormatOptions['weekday'] */
  format?: Intl.DateTimeFormatOptions['weekday'];
  /**
   * **deprecated** whether to use the format overrides or not
   * @deprecated
   */
  override?: boolean;
};

/**
 * Provides the days of the week for a given locale. The format for each day is determined by the `format` property.
 * This hook will order the days of the week based on the day the week starts for the given `locale`.
 *
 * #### Overrides
 * If the `override` flag is enabled, the following languages produce these day of week instead of the native support
 *
 * | Language | Short Days of Week |
 * |--------|-------------------|
 * | bg | 'пон', 'вто', 'сря', 'чет', 'пет', 'съб', 'нед' |
 * | cs | 'pon', 'úte', 'stř', 'čtv', 'pát', 'sob', 'ned' |
 * | ru | 'пнд', 'втр', 'срд', 'чтв', 'птн', 'суб', 'вск' |
 * | th | 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์' |
 */
export const useDaysOfWeek = ({
  locale,
  format = 'short',
  override = true,
}: UseDaysOfWeek): Array<string> =>
  useMemo(() => {
    if (!locale) {
      return [];
    }
    const options = {
      weekday: isArabic(locale) ? 'long' : format,
    };
    let days;
    if (format === 'short' && override) {
      days = locale.baseName ? FORMAT_SHORT_OVERRIDES[locale.baseName] : undefined;
    }
    if (!days) {
      const startDate = startOfWeek(new Date(), {
        weekStartsOn: weekStartsOn(locale),
      });
      days = [...Array(7)].map((n, i) => {
        const day = addDays(startDate, i);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return day.toLocaleDateString(locale, options);
      });
    }
    return days;
  }, [locale, format, override]);

/** these languages only supply 2 character short weekday values with Intl.DateTimeFormat */
const FORMAT_SHORT_OVERRIDES: Record<string, Array<string>> = {
  bg: ['пон', 'вто', 'сря', 'чет', 'пет', 'съб', 'нед'],
  cs: ['pon', 'úte', 'stř', 'čtv', 'pát', 'sob', 'ned'],
  ru: ['пнд', 'втр', 'срд', 'чтв', 'птн', 'суб', 'вск'],
  th: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
};

export default useDaysOfWeek;
