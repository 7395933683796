import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { Chip } from '@dx-ui/osc-chip';

type THotelChipProps = {
  variant: 'new' | 'renovated';
  className?: string;
};

const HotelChip = ({ className, variant }: THotelChipProps) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { t } = useTranslation('common');

  return (
    <span className={className}>
      <Chip
        as="span"
        label={variant === 'new' ? t('newHotel') : t('recentlyRenovated')}
        size={isDesktop ? 'lg' : 'sm'}
        className="text-nowrap"
      />
    </span>
  );
};

export default HotelChip;
