import { useRef, useEffect } from 'react';
import type { GuestQuery } from '@dx-ui/framework-auth-provider';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useInitConductrics } from '@dx-ui/framework-conductrics';

const getConductricsTraits = (isAuthenticated: boolean, guestInfo?: GuestQuery['guest'] | null) => {
  const traits = [`login:${isAuthenticated ? 'yes' : 'no'}`];
  const { hhonors } = guestInfo || {};

  if (hhonors?.summary?.tier) {
    traits.push(`tier:${hhonors.summary.tier.toLowerCase()}`);
  }
  // populate teammember status
  const teamMember =
    hhonors?.isOwner ||
    hhonors?.isTeamMember ||
    hhonors?.isOwnerHGV ||
    hhonors?.isLongTenure10 ||
    hhonors?.isLongTenure20;
  traits.push(`teamMember:${teamMember ? 'yes' : 'no'}`);

  return traits;
};

export const useInitializeConductrics = ({ traits }: { traits: string[] } = { traits: [] }) => {
  // Initialize conductrics (may or may not be needed for all tests, general information)
  const isConductricsInit = useRef(false);
  const { isLoading: authLoading, isAuthenticated, guestInfo } = useAuth();
  const initConductrics = useInitConductrics();

  useEffect(() => {
    if (!authLoading && !isConductricsInit.current) {
      const guestTraits = getConductricsTraits(isAuthenticated, guestInfo);
      isConductricsInit.current = true;
      initConductrics({ traits: [...traits, ...guestTraits] });
    }
  }, [isAuthenticated, authLoading, guestInfo, initConductrics, traits]);
};
