import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { forwardRef } from 'react';

type ActionButton = {
  adaDescription?: string;
  label?: string;
} & React.ComponentPropsWithRef<'button'>;

type EnablableActionButton = ActionButton & { isEnabled?: boolean };

export type ActionButtonProps = {
  buttonOptions?: {
    cancel?: ActionButton;
    confirm?: ActionButton;
    reset?: EnablableActionButton;
  };
  /** called when done button is activated */
  onConfirm?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onReset?: () => void;
  onDismiss?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const Button = forwardRef<HTMLButtonElement, ActionButton>(
  ({ label, adaDescription, className, ...rest }, forwardedRef) => {
    return (
      <button {...rest} className={cx('btn btn-lg', className)} ref={forwardedRef} type="button">
        {label}
        {adaDescription ? <span className="sr-only">{adaDescription}</span> : null}
      </button>
    );
  }
);

Button.displayName = 'Button';

export function ActionButtons({
  buttonOptions = {},
  onReset,
  onConfirm,
  onDismiss,
}: ActionButtonProps) {
  const { t } = useTranslation('osc-dialog');
  const { cancel, confirm, reset } = buttonOptions;
  const resetBtnEnabled = reset?.isEnabled && reset?.label;

  const buttons = [
    {
      ...confirm,
      onClick: onConfirm,
      label: confirm?.label ? confirm.label : t('done'),
      className: 'btn-primary',
      'data-testid': 'shop-modal-done-cta',
      ref: confirm?.ref,
    },
    {
      ...cancel,
      onClick: onDismiss,
      label: cancel?.label ? cancel.label : t('cancel'),
      className: 'btn-text-outline',
      ref: cancel?.ref,
    },
  ].map((props) => <Button {...props} key={props.label} />);

  return (
    <div
      className={cx(
        'bg-bg mb:pb-0 pt-2 px-1 sticky bottom-0 flex flex-col gap-4 md:flex-row md:rtl:flex-row',
        {
          'md:justify-between': resetBtnEnabled,
          'md:justify-end': !resetBtnEnabled,
        }
      )}
    >
      {resetBtnEnabled ? (
        <div className="md:self-center">
          <button
            className="btn-xl btn btn-primary-link w-full rounded-none"
            data-testid="shop-modal-reset-cta"
            onClick={() => onReset?.()}
            ref={reset?.ref}
            type="button"
          >
            {reset?.label}
            {reset?.adaDescription ? (
              <span className="sr-only" aria-live="assertive" role="alert">
                {reset?.adaDescription}
              </span>
            ) : null}
          </button>
        </div>
      ) : null}
      <div className="flex flex-col gap-3 md:flex-row ">{buttons}</div>
    </div>
  );
}
