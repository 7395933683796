import { useFormContext } from 'react-hook-form';
import { FormInput, FormSelect } from '@dx-ui/osc-form';
import { useCountries_AddressOptionsQuery, useGeocodeQuery } from './queries/generated/queries';
import type { AddressOptionsAddressType } from '@dx-ui/gql-types';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { AutoComplete } from './auto-complete';

const getAutoComplete = (name: string) => {
  switch (name) {
    case 'addressLine1':
    case 'businessAddressLine1':
      return AutoComplete.ADDRESSLINE1;
    case 'addressLine2':
    case 'businessAddressLine2':
      return AutoComplete.ADDRESSLINE2;
    case 'city':
      return AutoComplete.CITY;
    case 'state':
      return AutoComplete.STATE;
    case 'postalCode':
      return AutoComplete.POSTALCODE;
    default:
      return '';
  }
};

export const Modes = {
  Add: 'add',
  Edit: 'edit',
} as const;

export type ModesType = (typeof Modes)[keyof typeof Modes];

export function AddressFields({
  code = 'US',
  language = 'en',
  type = 'nonBusiness',
  groupName,
  componentState = Modes.Add,
  setLoadingGeocode,
}: {
  code: string;
  groupName?: string;
  language?: string;
  type?: AddressOptionsAddressType;
  componentState?: ModesType;
  setLoadingGeocode?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [t] = useTranslation('osc-join-form-form');
  const {
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { touchedFields },
    register,
    getFieldState,
  } = useFormContext();

  const [isZipInvalid, setIsZipInvalid] = useState(false);

  const shouldHideCityAndStateFields =
    code === 'US' && !isZipInvalid && componentState === Modes.Add;

  const getFieldKey = useCallback(
    (field: string): string => (groupName ? `${groupName}.${field}` : field),
    [groupName]
  );

  const isPostalCodeTouched = getFieldState(getFieldKey('postalCode'))?.isTouched;

  const zipCode = watch(getFieldKey('postalCode'));
  const city = watch(getFieldKey('city'));
  const state = watch(getFieldKey('state'));

  const { data: addressFieldData } = useCountries_AddressOptionsQuery(
    { language, code, type },
    { enabled: !!code }
  );
  const states = addressFieldData?.countries?.[0]?.states || [];

  // Set 'Please select' option as default for state select element
  useEffect(() => {
    if (componentState === Modes.Add) {
      if (!touchedFields[getFieldKey('state')]) {
        setValue(getFieldKey('state'), states.length > 0 ? 'pleaseSelect' : '');
      }
    }
  }, [getFieldKey, setValue, states, touchedFields, componentState]);

  const {
    data: geoLocationData,
    isLoading,
    error,
  } = useGeocodeQuery(
    {
      language,
      postalCode: watch(getFieldKey('postalCode')),
    },
    {
      enabled:
        !!/^(?!0{5,})\d{5}(-\d{4})?$/.test(zipCode) &&
        code === 'US' &&
        !(componentState === Modes.Edit && !isPostalCodeTouched),
    }
  );

  useEffect(() => {
    if (geoLocationData) {
      if (
        !geoLocationData?.geocode?.match?.address?.city ||
        !geoLocationData?.geocode?.match?.address?.state
      ) {
        setIsZipInvalid(true);
        setValue(getFieldKey('city'), '');
        setValue(getFieldKey('state'), 'pleaseSelect');
        setError(getFieldKey('postalCode'), {
          type: 'validate',
          message: t('address.zipCode.error_lookup'),
        });
      } else {
        clearErrors(getFieldKey('postalCode'));
        clearErrors(getFieldKey('city'));
        setValue(getFieldKey('city'), geoLocationData?.geocode?.match?.address?.city);
        setValue(getFieldKey('state'), geoLocationData?.geocode?.match?.address?.state);
        setIsZipInvalid(false);
      }
    } else if (error) {
      const errors = JSON.parse(JSON.stringify(error));
      for (const error of errors.graphQLErrors) {
        if (error?.message === 'Not Found') {
          setIsZipInvalid(true);
          setValue(getFieldKey('city'), '');
          setValue(getFieldKey('state'), 'pleaseSelect');
          setError(getFieldKey('postalCode'), {
            type: 'validate',
            message: t('address.zipCode.error_lookup'),
          });
        }
      }
    }
  }, [geoLocationData, setValue, getFieldKey, setError, t, clearErrors, error]);

  useEffect(() => {
    // set state to enable/ disable submit button depending on geocode query
    if (setLoadingGeocode) {
      if (isLoading) setLoadingGeocode(true);
      else setLoadingGeocode(false);
    }
  }, [isLoading, setLoadingGeocode]);

  const fields = addressFieldData?.countries?.[0]?.addressOptions;
  if (!fields || !/^\w{2}$/.test(code)) {
    return null;
  }

  return (
    <>
      {fields.map(({ name, label, required }) => {
        if (shouldHideCityAndStateFields && (name === 'state' || name === 'city')) {
          return <input key={getFieldKey(name)} type="hidden" {...register(getFieldKey(name))} />;
        }

        if (name === 'state' && states.length > 0) {
          return (
            <FormSelect
              label={label}
              labelClassName="w-full"
              name={getFieldKey(name)}
              key={getFieldKey(name)}
              autoComplete={AutoComplete.STATE}
              optional={!required}
              registerOptions={{
                validate: (value) => {
                  if (!required) {
                    return true;
                  }
                  return value === 'pleaseSelect' || !value
                    ? code === 'US'
                      ? t('address.state.error')
                      : t('address.province.error')
                    : true;
                },
              }}
            >
              <option disabled hidden value="pleaseSelect">
                {t('pleaseSelect')}
              </option>
              {states.map((state) => (
                <option value={state.value} key={state.value}>
                  {state.name}
                </option>
              ))}
            </FormSelect>
          );
        }

        return (
          <FormInput
            label={label}
            labelClassName="w-full flex-grow"
            name={getFieldKey(name)}
            autoComplete={getAutoComplete(name)}
            loading={name === 'postalCode' && isLoading}
            optional={!required}
            key={getFieldKey(name)}
            registerOptions={{
              validate: (value: string) => {
                if (!required || (!!value && name !== 'postalCode')) {
                  return true;
                }
                switch (name) {
                  case 'addressLine1':
                  case 'businessAddressLine1':
                    if (code === 'JP') {
                      return t('address.district.error');
                    }
                    return t('address.addressLine1.error');
                  case 'state':
                    return t('address.province.error');
                  case 'city':
                    if (code === 'JP') {
                      return t('address.ward.error');
                    }
                    return t('address.city.error');
                  case 'postalCode':
                    if (code === 'US') {
                      if (/^(?!0{5,})\d{5}(-\d{4})?$/.test(value)) {
                        if ((isZipInvalid || !geoLocationData?.geocode) && (!city || !state)) {
                          return t('address.zipCode.error_lookup');
                        }
                        return true;
                      }
                      if (value === '' && (!city || !state) && type === 'nonBusiness') {
                        return t('address.zipCode.invalid');
                      }
                      return value === ''
                        ? t('address.zipCode.error')
                        : t('address.zipCode.invalid');
                    }
                    return required && value ? true : t('address.postalCode.error');
                  default:
                    if (name === 'businessAddressLine1') return t('address.addressLine1.error');
                    if (name === 'businessAddressLine2') return t('address.addressLine2.error');
                    return t(`address.${name}.error` as unknown as TemplateStringsArray);
                }
              },
            }}
            {...(name === 'addressLine1' ||
            name === 'businessAddressLine1' ||
            name === 'addressLine2' ||
            name === 'businessAddressLine2' ||
            name === 'city'
              ? { maxLength: 35 }
              : {})}
          />
        );
      })}
    </>
  );
}
