import { memo } from 'react';
import { Breadcrumbs } from '@dx-ui/osc-breadcrumbs';

type BreadCrumbsProps = {
  breadCrumbs?: { url?: string | null; name?: string | null }[];
};

const BreadCrumb = function ({ breadCrumbs }: BreadCrumbsProps) {
  const sanitizedBreadCrumbs = breadCrumbs
    ?.filter((breadCrumb) => breadCrumb.url && breadCrumb.name)
    .map((breadCrumb) => ({ uri: breadCrumb.url, name: breadCrumb.name }));

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadCrumbs
      ?.map(({ name, url }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': url,
          '@type': 'WebPage',
          name,
        },
      }))
      .slice(0, -1),
  };
  return (
    <>
      {(sanitizedBreadCrumbs?.length ?? 0) > 0 ? (
        <div className="border-border border-t border-solid">
          <Breadcrumbs breadcrumbs={sanitizedBreadCrumbs} className="container py-4" />
        </div>
      ) : null}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </>
  );
};

export const BreadCrumbs = memo(BreadCrumb);
