import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

export const useStickyShopFormTest = () => {
  const { isLoaded, selection } = useConductricsSelection('a-c5ZpUJ9UEFi4', Status.OK);
  const isStickyShopFormVariant = isLoaded && selection?.choice === 'B';
  const isShopFormVisibleOnScrollVariant = isLoaded && selection?.choice === 'C';

  return {
    isStickyShopFormVariant,
    isShopFormVisibleOnScrollVariant,
  };
};
