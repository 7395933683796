import type * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  useShopFormContext,
  ShopFormDates,
  ShopFormGroup,
  ShopFormRooms,
  ShopFormSpecialRates,
} from '@dx-ui/osc-shop-form';
import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { sendRewardAsync, Status } from '@dx-ui/framework-conductrics';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import type {
  TSearchState,
  useSearchContext,
} from '../../helpers/providers/search/search-provider';
import type { LayoutData } from '../../helpers/layout.types';
import { useMonthDateSelectorTest } from '../../hooks/use-month-date-selector-test';
import { useShowShopByPriceButtonTest } from '../../hooks/use-shop-by-price-button-on-date-selector-test';
import { GOALS } from '../../helpers/conductricsConstants';
import { SearchMonthDateSelector } from './SearchMonthDateSelector';

type TSearchWidgetForm = {
  language: string;
  searchState: TSearchState;
  onSearchChange: ReturnType<typeof useSearchContext>['onSearchChange'];
  initializeSearchState: ReturnType<typeof useSearchContext>['initializeSearchState'];
  isGroupSearch: boolean;
  showNumAttendees?: boolean;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
  minArrivalDate: string;
  maxArrivalDate: string;
  ctyhocn: string;
  currencyCode?: string;
};

type ModalType = 'dates' | 'rooms' | 'specialRates' | null;
const MODAL_SESSION_KEY = 'property_search_widget_modal';

export const SearchWidgetForm = ({
  language,
  searchState,
  onSearchChange,
  initializeSearchState,
  isGroupSearch,
  showNumAttendees,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
  minArrivalDate,
  maxArrivalDate,
  ctyhocn,
  currencyCode,
}: TSearchWidgetForm) => {
  const { t } = useTranslation('osc-rooms');
  const { watch } = useShopFormContext();
  const canOnlyBookSingleRoom = maxNumRooms === 1;
  const router = useRouter();
  const [isShopFormDateViewLoaded, setShopFormDateViewLoaded] = useState(false);
  const { isMonthTabSelectionVariant: hasMonthDateSelector } =
    useMonthDateSelectorTest(isShopFormDateViewLoaded);
  const formState = watch();
  const hasTodayAsDefault = !!(
    searchState?.dates?.arrivalDate && searchState?.dates?.departureDate
  );
  const [activeModal, setActiveModal] = useState<ModalType>(
    () => (window?.sessionStorage?.getItem(MODAL_SESSION_KEY) as ModalType) || null
  );

  const ageRange =
    ageBasedPricing && adultAge
      ? {
          max: adultAge,
          min: 0,
        }
      : undefined;
  const agesRequired = !!ageRange;
  const [showShopByPriceStatus, setShowShopByPriceStatus] = useState<AgentStatus>(Status.PENDING);
  const { shouldShowShopByPriceButton } = useShowShopByPriceButtonTest(showShopByPriceStatus);

  const handleModalOpen = (type: ModalType) => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, String(type));
    if (type === 'dates') {
      setShowShopByPriceStatus(Status.OK);
    }
    setActiveModal(type);
    setShopFormDateViewLoaded(true);
  };

  const handleDismiss = () => {
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const handleConfirmAndClose = () => {
    onSearchChange({ ...formState, displayCurrency: searchState.displayCurrency });
    window?.sessionStorage?.setItem(MODAL_SESSION_KEY, '');
    setActiveModal(null);
  };

  const occupancyLimitMessage = canOnlyBookSingleRoom
    ? t('occupancy.occupancySingleRoomLimitMessage')
    : t('occupancy.occupancyLimitMessage');

  const MonthDateSelectorComponent = useCallback(
    ({
      selectedCalendarDate,
      onMonthUpdate,
    }: {
      selectedCalendarDate?: Date;
      onMonthUpdate?: (d: Date) => void;
    }): React.ReactNode => {
      return (
        <SearchMonthDateSelector
          minArrivalDate={minArrivalDate}
          maxArrivalDate={maxArrivalDate}
          locale={language}
          selectedCalendarDate={selectedCalendarDate}
          onMonthUpdate={onMonthUpdate}
        />
      );
    },
    [language, maxArrivalDate, minArrivalDate]
  );

  const shopFormDateProps = {
    hasTodayAsDefault,
    language,
    isOpen: activeModal === 'dates',
    onConfirm: () => {
      handleConfirmAndClose();
    },
    onOpen: () => handleModalOpen('dates'),
    onDismiss: handleDismiss,
    hideFlexDates: hideFlexDates || shouldShowShopByPriceButton,
    ...(hasMonthDateSelector
      ? {
          MonthDateSelectorComponent,
        }
      : null),
    ...(shouldShowShopByPriceButton && !hideFlexDates
      ? {
          afterCalendarContent: (
            <div>
              <span className="mx-4 py-2 font-bold">Flexible travel dates?</span>
              <button
                type="button"
                data-testid="shop-flexible-dates-button"
                className="btn btn-text-outline text-sm"
                onClick={async () => {
                  if (shouldShowShopByPriceButton) {
                    await sendRewardAsync(GOALS.FLEXIBLE_DATES_CTA);
                    await router.push(
                      bookUriBuilder({
                        urlParams: {
                          ctyhocn,
                        },
                        page: 'flexibledates',
                      })
                    );
                  }
                }}
              >
                Shop by Price
              </button>
            </div>
          ),
        }
      : null),
  };

  useEffect(() => {
    initializeSearchState({
      ...formState,
      displayCurrency: searchState.displayCurrency || currencyCode,
    });
  }, [currencyCode, formState, initializeSearchState, searchState.displayCurrency]);

  useEffect(() => {
    if (
      searchState?.numAttendees !== formState?.numAttendees ||
      searchState?.numRooms !== formState?.numRooms
    ) {
      onSearchChange(
        { ...formState, displayCurrency: searchState.displayCurrency },
        { skipStateUpdate: true }
      );
    }
  }, [
    formState,
    onSearchChange,
    searchState?.numAttendees,
    searchState?.numRooms,
    searchState?.displayCurrency,
  ]);

  return (
    <>
      <ShopFormDates {...shopFormDateProps} />
      {isGroupSearch ? (
        <ShopFormGroup showNumAttendees={showNumAttendees} />
      ) : (
        <>
          <ShopFormRooms
            occupancyLimitMessage={occupancyLimitMessage}
            ageRange={ageRange}
            open={activeModal === 'rooms'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('rooms')}
            onDismiss={handleDismiss}
            agesRequired={agesRequired}
            adultAge={adultAge ?? undefined}
            isAdultsOnly={isAdultsOnly}
            hideGroupLink={isPartnerBrand}
            maxRooms={maxNumRooms}
            maxOccupants={maxOccupants}
          />
          <ShopFormSpecialRates
            open={activeModal === 'specialRates'}
            onConfirm={handleConfirmAndClose}
            onOpen={() => handleModalOpen('specialRates')}
            onDismiss={handleDismiss}
          />
        </>
      )}
    </>
  );
};
