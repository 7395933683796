import * as React from 'react';
import { ActionButtons, type ActionButtonProps } from './dialog-action-buttons';
import { DialogBase, type DialogBaseProps, type DialogOptionalProps } from './dialog.base';

export type ActionDialogProps = React.PropsWithChildren<
  DialogBaseProps & ActionButtonProps & DialogOptionalProps
>;

/**
 * A dialog with two buttons for the user to take an action to cancel or accept.
 */
export function ActionDialog({
  buttonOptions,
  onCancel,
  onConfirm,
  onDismiss,
  onReset,
  ...dialogProps
}: ActionDialogProps) {
  const dialogRef = React.useRef<React.ElementRef<'div'>>(null);

  const handleCancel = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    onCancel?.(event);
  };

  const handleConfirm = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    onConfirm?.(event);
  };

  const bottomContent = (
    <ActionButtons
      buttonOptions={buttonOptions}
      onReset={onReset}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
  return (
    <DialogBase
      {...dialogProps}
      onDismiss={onDismiss}
      bottomContent={bottomContent}
      ref={dialogRef}
    />
  );
}
