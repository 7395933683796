import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { useEffect, useState } from 'react';

export const useRenovationFlagTest = (ctyhocn: string) => {
  const [status, setStatus] = useState<AgentStatus>(Status.PENDING);
  const { isLoaded, selection } = useConductricsSelection('a-PUFYlSF6GT', status);

  const recentlyRenovated = Object.hasOwn(selection?.meta ?? {}, ctyhocn);

  useEffect(() => {
    if (recentlyRenovated) {
      setStatus(Status.OK);
    }
  }, [recentlyRenovated]);

  const showRenovationChipInUtilityRail =
    isLoaded && selection?.choice === 'B' && recentlyRenovated;

  const showRenovationChipInHeader = isLoaded && selection?.choice === 'C' && recentlyRenovated;

  return {
    showRenovationChipInUtilityRail,
    showRenovationChipInHeader,
  };
};
