import type * as React from 'react';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';

const NavItemWithLink: React.FC<
  React.PropsWithChildren<Link & { theme?: Exclude<CmsBrandComponentTheme, 'light'> }>
> = ({ children, className, onClick, theme, ...rest }) => {
  const isDark = theme === 'dark';

  return (
    <li
      className={cx('group flex h-full', {
        'text-text nav-list-item': !theme,
        'nav-list-item-dark': isDark,
      })}
    >
      <div className="mx-1 flex h-full items-center pt-1 [&_a]:flex [&_a]:h-full [&_a]:items-center">
        <Link
          underline={false}
          className={cx('block whitespace-nowrap px-2', className)}
          onClick={onClick}
          {...rest}
        >
          {children}
        </Link>
      </div>
    </li>
  );
};

export default NavItemWithLink;
