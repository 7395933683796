import { useEffect, useState } from 'react';
import type { AgentStatus } from '@dx-ui/framework-conductrics';
import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

export const useMonthDateSelectorTest = (isShopFormDateViewLoaded: boolean) => {
  const [status, setStatus] = useState<AgentStatus>(Status.PENDING);
  const { isLoaded, selection } = useConductricsSelection('a-ZnHL0zQmF22c', status);
  const isMonthTabSelectionVariant = isLoaded && selection?.choice === 'B';

  useEffect(() => {
    if (isShopFormDateViewLoaded) {
      setStatus(Status.OK);
    }
  }, [isShopFormDateViewLoaded]);

  return {
    isMonthTabSelectionVariant,
  };
};
