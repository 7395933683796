import { Tooltip } from '@dx-ui/osc-tooltip';
import { Stepper } from '@dx-ui/osc-stepper';
import { FormSelect } from '@dx-ui/osc-form';
import * as React from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import Icon from '@dx-ui/osc-icon';
import { logError } from '@dx-ui/framework-logger';

type RoomsRoom = {
  adultAge?: number;
  isAdultsOnly?: boolean;
  /**
   * if ageRange is provided, Age based pricing is in effect
   * */
  ageRange?: {
    min: number;
    max: number;
  };
  /**
   * if ages are required to get the best rates, making `agesRequired` will show the age select elements for each child entered.
   */
  agesRequired?: boolean;
  /**
   * disables the add room button if number of rooms is greater than this value
   */
  maxRooms?: number;
  /**
   * the maximum number of collective occupants allowed per room
   * be it adults or children with a min of one adult
   */
  maxOccupants?: number;
  index: number;
  onRemoveRoom: (val: number) => void;
};

const RoomsRoom: React.FC<React.PropsWithChildren<RoomsRoom>> = ({
  adultAge: adultAgeProp,
  isAdultsOnly,
  agesRequired,
  ageRange,
  index,
  onRemoveRoom,
  maxOccupants = 8,
}) => {
  const [t] = useTranslation('osc-rooms');
  const { setValue, watch, control, trigger } = useFormContext();
  const useAgeBasedPricing = Boolean(ageRange);
  const adultsId = `rooms.${index}.adults` as const;
  const childrenId = `rooms.${index}.children` as const;
  const adultAge = adultAgeProp || ageRange?.max;
  const adultAges = adultAge ? t('occupancy.adultAges', { adultAge }) : '';
  const { fields, remove, append } = useFieldArray({
    control,
    name: childrenId,
  });
  const roomsValue = watch('rooms');
  const numRooms = roomsValue?.length || 0;
  const adults = watch(adultsId);
  const children = watch(childrenId) || [];
  const roomNum = index + 1;
  const roomTestId = `rooms-room-${roomNum}`;

  React.useEffect(() => {
    const getChildAgesFieldsAndValidate = async () => {
      if (fields.length > 0 && agesRequired) {
        // get all the children age field names
        const names = fields.map((field, i) => `${childrenId}.${i}.age` as const);
        // validate immediately
        await trigger(names);
      }
    };
    getChildAgesFieldsAndValidate().catch((error) =>
      logError('OSC_ROOMS', error, 'failed to validate children ages')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, agesRequired]);
  return (
    <div data-testid={roomTestId}>
      <div className="sr-only" aria-live="polite">
        <span>{`${numRooms > 1 ? `${t('occupancy.roomHas', { num: roomNum })} ` : ''} ${t(
          'occupancy.adult',
          {
            count: adults,
          }
        )}, ${t('occupancy.child', { count: children.length })}`}</span>
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex w-1/3 items-center">
          {numRooms > 1 && (
            <button
              data-testid={`${roomTestId}-remove`}
              type="button"
              className="stroke-primary hover:stroke-primary-alt mr-2 appearance-none rounded-full hover:duration-100"
              onClick={() => onRemoveRoom(index)}
            >
              <span className="sr-only">{t('occupancy.removeRoom', { num: roomNum })}</span>
              <svg role="img" aria-hidden viewBox="0 0 27 27" className="stroke-primary size-6">
                <g fill="none" fillRule="evenodd" transform="translate(-4.4 -4.4)">
                  <ellipse
                    transform="rotate(45 17.836 17.836)"
                    cx="17.836"
                    cy="17.836"
                    rx="12.5"
                    ry="12.724"
                  />
                  <path d="M13.585 13.572l8.515 8.515m-8.592.047l8.668-8.668" />
                </g>
              </svg>
            </button>
          )}
          <span>{t('occupancy.room', { num: roomNum })}</span>
        </div>
        <div className="w-1/3">
          <Stepper
            onMinusClick={() => {
              setValue(adultsId, adults - 1, { shouldDirty: true, shouldValidate: true });
            }}
            onPlusClick={() => {
              setValue(adultsId, adults + 1, { shouldDirty: true, shouldValidate: true });
            }}
            min={1}
            max={maxOccupants - children.length}
            value={adults}
            valueLabel={t('occupancy.adult', { count: adults })}
            plusLabel={t('occupancy.addAdult', { num: roomNum, adultAges })}
            minusLabel={t('occupancy.removeAdult', { num: roomNum, adultAges })}
          />
        </div>
        <div className="w-1/3">
          <Stepper
            onMinusClick={() => {
              remove(fields.length - 1);
            }}
            onPlusClick={() => {
              // NHCSEARCH-4849 - ShouldFocus false keeps focus on stepper button
              append({ age: null }, { shouldFocus: false });
            }}
            min={0}
            max={isAdultsOnly ? 0 : maxOccupants - adults}
            value={children.length}
            valueLabel={t('occupancy.child', { count: children.length })}
            plusLabel={t('occupancy.addChild', { num: roomNum })}
            minusLabel={t('occupancy.removeChild', { num: roomNum })}
          />
        </div>
      </div>

      {useAgeBasedPricing && children.length > 0 && (
        <div className="pt-2" data-testid="rooms-age-based">
          <h2 className="flex items-center space-x-2 pb-2 text-sm font-bold">
            <span>{t('occupancy.agesLabel')}</span>
            <Tooltip message={t('occupancy.ageBasedHelp')} className="btn btn-primary-text btn-lg">
              <Icon name="info-circle" variant="regular" size="md" />
            </Tooltip>
          </h2>
          <div className="flex max-w-44 flex-col space-y-2 pl-4">
            {fields.map((field, i) => {
              const fieldName = `${childrenId}.${i}.age` as const;
              const ageId = i + 1;

              const registerOptions: RegisterOptions = {
                onChange: async () => {
                  await trigger(fieldName);
                },
              };
              if (agesRequired) {
                registerOptions.required = t('occupancy.ageBasedError');
              }
              return (
                <FormSelect
                  key={field.id}
                  data-testid={`${roomTestId}-childage-${ageId}`}
                  name={fieldName}
                  labelClassName="label-inline !self-auto"
                  containerClassName="text-right"
                  label={t('occupancy.kidLabel', { num: ageId })}
                  registerOptions={{
                    valueAsNumber: true,
                    ...registerOptions,
                  }}
                >
                  <option disabled value="" />
                  {Array.from({ length: ageRange!.max - ageRange!.min }).map((__, j) => {
                    const value = j + ageRange!.min;
                    return (
                      <option key={value === 0 ? t('occupancy.underOne') : value} value={value}>
                        {value === 0 ? t('occupancy.underOne') : value}
                      </option>
                    );
                  })}
                </FormSelect>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export { RoomsRoom };
export default RoomsRoom;
