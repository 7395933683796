import type { RefObject } from 'react';
import { useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export const useStickyLayout = ({
  ref,
  thresholdSticky = 33,
}: {
  ref: RefObject<HTMLElement>;
  thresholdSticky?: number;
}): boolean => {
  const [isSticky, setIsSticky] = useState(false);
  useResizeObserver({
    ref,
    onResize: ({ height = 0 }) => {
      const heightVh = Math.round((height / window.innerHeight) * 100);
      setIsSticky(heightVh < thresholdSticky);
    },
  });

  return isSticky;
};
