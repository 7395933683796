import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { addDays, isBefore } from 'date-fns';
import { useRouter } from 'next/router';
import { useIsClient } from 'usehooks-ts';
import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import type { FormDefaultValues } from '@dx-ui/osc-shop-form';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { wrapperClassNameDefault, ShopForm } from '@dx-ui/osc-shop-form';
import { baseUrl } from '../../helpers/env-vars';
import { useSearchContext } from '../../helpers/providers/search/search-provider';
import { SearchWidgetForm } from './SearchWidgetForm';
import { GOALS } from '../../helpers/conductricsConstants';
import type { LayoutData } from '../../helpers/layout.types';
import { useSearchParams } from 'next/navigation';
import type { Child } from '@dx-ui/framework-uri-builder';
import {
  availableSearchParamsMapper,
  formatDateString,
  shapeRoomsToDefaultDataStructure,
} from './search-helpers';

export type AdultsAndChildrenRoom = {
  adults?: number;
  children?: Child[];
  roomNumber: number;
};

export type RoomAges = { roomNumber: number; childAges: Child[] };

export type TSearchWidgetContainer = {
  ctyhocn: string;
  isGroupSearch: boolean;
  showNumAttendees: boolean;
  isResEnabled: boolean;
  resEnabledDate: string;
  isOpen: boolean;
  openDate: string;
  ageBasedPricing: LayoutData['ageBasedPricing'];
  isAdultsOnly: boolean;
  adultAge: LayoutData['adultAge'];
  isPartnerBrand: boolean;
  brandCode: string;
  maxOccupants: number;
  maxNumRooms: number;
  hideFlexDates: boolean;
  currencyCode?: string;
  minArrivalDate: string;
  maxArrivalDate: string;
};

export type InitialSearch = Omit<FormDefaultValues, 'rooms'> & {
  ages?: RoomAges[];
  rooms: AdultsAndChildrenRoom[];
};

const SearchWidgetContainer = ({
  ctyhocn,
  isGroupSearch,
  showNumAttendees,
  isResEnabled,
  resEnabledDate,
  isOpen,
  openDate,
  ageBasedPricing,
  isAdultsOnly,
  adultAge,
  isPartnerBrand,
  brandCode,
  maxOccupants,
  maxNumRooms,
  hideFlexDates,
  currencyCode,
  minArrivalDate,
  maxArrivalDate,
}: TSearchWidgetContainer) => {
  const { searchState, onSearchChange, initializeSearchState } = useSearchContext();
  const language = useRouter().locale || 'en';
  const { guestInfo } = useAuth();
  const { t } = useTranslation('dx-search');
  const isClient = useIsClient();
  const searchQueryParams = useSearchParams();

  const initialSearchQueryParams = availableSearchParamsMapper(searchQueryParams);

  const searchStateFromParamsRooms = shapeRoomsToDefaultDataStructure(
    initialSearchQueryParams.rooms,
    initialSearchQueryParams.ages
  ).filter((room) => !!room.adults);

  const searchStateFromParams: FormDefaultValues = {
    ...initialSearchQueryParams,
    rooms: searchStateFromParamsRooms.length ? searchStateFromParamsRooms : undefined,
  };

  const getStartDate = () => {
    if (!isOpen) {
      if (isResEnabled) {
        return formatDateString(resEnabledDate);
      } else {
        return formatDateString(openDate);
      }
    }
    if (isGroupSearch) {
      return addDays(new Date(Date.now()), 7);
    }
    return new Date(Date.now());
  };

  const day: Date = getStartDate();
  const searchStateArrivalDate = searchState?.dates?.arrivalDate
    ? new Date(searchState?.dates.arrivalDate)
    : null;
  const searchStateDepartureDate = searchState?.dates?.departureDate
    ? new Date(searchState?.dates.departureDate)
    : null;

  const arrivalDate =
    searchStateArrivalDate && !isBefore(searchStateArrivalDate, day) ? searchStateArrivalDate : day;

  const departureDate =
    searchStateDepartureDate && !isBefore(searchStateDepartureDate, day)
      ? searchStateDepartureDate
      : addDays(day, isGroupSearch ? 2 : 1);

  const defaultValues: FormDefaultValues = {
    brandCode,
    dates: {
      arrivalDate: searchStateFromParams?.dates?.arrivalDate || arrivalDate,
      departureDate: searchStateFromParams?.dates?.departureDate || departureDate,
      datesFlex: searchStateFromParams?.dates?.datesFlex || searchState?.dates?.datesFlex,
    },
    rooms: searchStateFromParams.rooms || searchState?.rooms,
    hhonors: guestInfo?.hhonors,
    query: ctyhocn,
    numAttendees: isGroupSearch ? searchState?.numAttendees || 0 : null,
    numRooms: isGroupSearch ? searchState?.numRooms ?? 10 : null,
    meetingSpace: searchState?.meetingSpace ?? false,
    specialRates: searchStateFromParams.specialRates || searchState?.specialRates,
  };

  const searchFormKey = isBrowser
    ? window.btoa(encodeURI(JSON.stringify(defaultValues)))
    : 'search-form';

  if (!isClient) {
    return null;
  }

  return (
    <ShopForm
      additionalQSParameters={{ displayCurrency: searchState?.displayCurrency }}
      defaultValues={defaultValues}
      language={language}
      wrapperClassName={cx([...wrapperClassNameDefault, 'w-fit'].slice(1))}
      submitOpensNewTab={true}
      targetOHWPage="book"
      key={searchFormKey}
      cta={t('ctaButton')}
      hasErrorBanner={false}
      onSubmit={async ({ url }) => {
        await sendRewardAsync(GOALS.EDIT_SEARCH_WIDGET);
        window.open(baseUrl + url, '_blank', 'noopener');
      }}
    >
      <SearchWidgetForm
        language={language}
        onSearchChange={onSearchChange}
        initializeSearchState={initializeSearchState}
        isGroupSearch={isGroupSearch}
        showNumAttendees={showNumAttendees}
        searchState={searchState || defaultValues}
        ageBasedPricing={ageBasedPricing}
        isAdultsOnly={isAdultsOnly}
        adultAge={adultAge}
        isPartnerBrand={isPartnerBrand}
        maxOccupants={maxOccupants}
        maxNumRooms={maxNumRooms}
        hideFlexDates={hideFlexDates}
        minArrivalDate={minArrivalDate}
        maxArrivalDate={maxArrivalDate}
        ctyhocn={ctyhocn}
        currencyCode={currencyCode}
      />
    </ShopForm>
  );
};

export default SearchWidgetContainer;
