import type * as Types from './types';

import {
  useQuery,
  useMutation,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
  type UseMutationOptions,
} from '@tanstack/react-query';

export const Countries_AddressOptionsDocument = {
  operationName: 'countries_AddressOptions',
  operationString: `query countries_AddressOptions($language: String!, $code: String, $type: AddressOptionsAddressType) {
  countries(language: $language, filter: {code: $code}) {
    code
    name
    addressOptions(type: $type) {
      name
      label
      maxLength
      required
    }
    states(sort: {by: name, order: asc}) {
      value: code
      name
    }
  }
}`,
  originalOpName: 'countries_AddressOptions',
};
export function useCountries_AddressOptionsQuery<
  TData = Types.Countries_AddressOptionsQuery,
  TError = unknown
>(
  variables: Types.Countries_AddressOptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Countries_AddressOptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Countries_AddressOptionsQuery, TError, TData>({
    queryKey: [Countries_AddressOptionsDocument, variables],
    ...options,
  });
}
export function serverSideCountries_AddressOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Countries_AddressOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Countries_AddressOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Countries_AddressOptionsQuery>({
    queryKey: [Countries_AddressOptionsDocument, variables],
    ...options,
  });
}

export const GeocodeDocument = {
  operationName: 'geocode',
  operationString: `query geocode($postalCode: String, $language: String!) {
  geocode(language: $language, postalCode: $postalCode) {
    match {
      address {
        city
        state
      }
    }
  }
}`,
  originalOpName: 'geocode',
};
export function useGeocodeQuery<TData = Types.GeocodeQuery, TError = unknown>(
  variables: Types.GeocodeQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodeQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodeQuery, TError, TData>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}
export function serverSideGeocodeQuery(
  queryClient: QueryClient,
  variables: Types.GeocodeQueryVariables,
  options?: FetchQueryOptions<Types.GeocodeQuery>
) {
  return queryClient.fetchQuery<Types.GeocodeQuery>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}

export const Brand_CountriesDocument = {
  operationName: 'brand_countries',
  operationString: `query brand_countries($after: String, $containsHotels: Boolean, $first: Int, $language: String!, $countryFilter: CountryFilterInput, $regionFilter: CountryStateFilterInput, $cityFilter: CountryCityFilterInput, $getStates: Boolean = false, $getCities: Boolean = false, $getMarketing: Boolean = false, $getCCSMarketing: Boolean = false) {
  countries(
    after: $after
    containsHotels: $containsHotels
    filter: $countryFilter
    first: $first
    language: $language
    sort: {by: name}
  ) {
    code
    callingCode
    dataSubjectRights
    marketingConsent @include(if: $getMarketing)
    marketingOptIn @include(if: $getMarketing)
    marketingOptInForHGV @include(if: $getMarketing)
    marketingOptin @include(if: $getCCSMarketing) {
      dataSubjectRights
      marketingOptinRules {
        category
        optIns {
          autoOptIn
          _id
          customerTextExternal
          name
          subscriptionCodes
          termsAndConditionsBook
          termsAndConditionsJoin
        }
      }
    }
    name: displayName
    states(filter: $regionFilter) @include(if: $getStates) {
      name
      code
      personalDataRequest
      value: code
      cities(filter: $cityFilter, sort: {by: name, order: asc}) @include(if: $getCities) {
        name
        value: name
        brandCodes
      }
    }
    cities(filter: $cityFilter, onlyIfNoStates: true, sort: {by: name, order: asc}) @include(if: $getCities) {
      name
      value: name
      brandCodes
    }
  }
}`,
  originalOpName: 'brand_countries',
};
export function useBrand_CountriesQuery<TData = Types.Brand_CountriesQuery, TError = unknown>(
  variables: Types.Brand_CountriesQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CountriesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CountriesQuery, TError, TData>({
    queryKey: [Brand_CountriesDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CountriesQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CountriesQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CountriesQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CountriesQuery>({
    queryKey: [Brand_CountriesDocument, variables],
    ...options,
  });
}

export const CreateGuestDocument = {
  operationName: 'createGuest',
  operationString: `mutation createGuest($input: EnrollInput!, $language: String!, $recaptchaInput: RecaptchaInput) {
  createGuest(input: $input, language: $language, recaptchaInput: $recaptchaInput) {
    data {
      guestId
      hhonorsNumber
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuest',
};
export function useCreateGuestMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestMutation,
    TError,
    [typeof CreateGuestDocument, Types.CreateGuestMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestMutation,
    TError,
    [typeof CreateGuestDocument, Types.CreateGuestMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Languages_DefaultCountryCodeDocument = {
  operationName: 'languages_defaultCountryCode',
  operationString: `query languages_defaultCountryCode($language: String!) {
  languages(language: $language, filter: {languageCodeOHW: $language}) {
    defaultCountryCode
  }
}`,
  originalOpName: 'languages_defaultCountryCode',
};
export function useLanguages_DefaultCountryCodeQuery<
  TData = Types.Languages_DefaultCountryCodeQuery,
  TError = unknown
>(
  variables: Types.Languages_DefaultCountryCodeQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Languages_DefaultCountryCodeQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Languages_DefaultCountryCodeQuery, TError, TData>({
    queryKey: [Languages_DefaultCountryCodeDocument, variables],
    ...options,
  });
}
export function serverSideLanguages_DefaultCountryCodeQuery(
  queryClient: QueryClient,
  variables: Types.Languages_DefaultCountryCodeQueryVariables,
  options?: FetchQueryOptions<Types.Languages_DefaultCountryCodeQuery>
) {
  return queryClient.fetchQuery<Types.Languages_DefaultCountryCodeQuery>({
    queryKey: [Languages_DefaultCountryCodeDocument, variables],
    ...options,
  });
}
