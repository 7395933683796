import * as React from 'react';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { GuestLoginConfigOptions, LoginResponse, GuestLoginResponse } from './login.types';
import { LoginFormEventType } from './login.types';

type LoginIFrame = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  /** Title of the iframe */
  title: string;
  /** The source for the iframe to use */
  frameSrc: string;
  /**
   * Callback for login attempts. Handles both success and error.
   *
   * > NOTE: `onLogin` and `onLoginError` are **DEPRECATED** in favor of this prop
   * */
  onLoginAttempt: (response: LoginResponse<any>) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  /** Options for the iframe */
  options?: GuestLoginConfigOptions;
  /** Handler for closing, if used in a modal */
  onClose?: () => void;
  /**
   * **deprecated** - Do not use - use `onLoginAttempt`
   * @deprecated
   */
  onLogin?: (data: NonNullable<LoginResponse['data']>) => void;
  /**
   * **deprecated** - Do not use - use `onLoginAttempt`
   * @deprecated
   */
  onLoginError?: (error: NonNullable<LoginResponse['error']>) => void;
};

/**
 * This component can be used separately and provides the communication logic between the parent windown and the iframe.
 *
 * `import { LoginIFrame } from '@dx-ui/osc-login'`
 */
export const LoginIFrame: React.FC<React.PropsWithChildren<LoginIFrame>> = ({
  frameSrc,
  options = {},
  onClose,
  onLoginAttempt,
  onLogin,
  title,
  onLoginError,
  ...rest
}: LoginIFrame) => {
  const readyCount = React.useRef(0);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const noOptions = Object.keys(options).length === 0;
  const [frameHeight, setFrameHeight] = React.useState(291.25);

  const handleMessage = React.useCallback(
    (event: MessageEvent<GuestLoginResponse>) => {
      const {
        data: { handler, type, height, data, error, status },
      } = event;
      // @todo remove - Keeping status, handler for backwards compatibility
      if (
        (status === 'ready' ||
          type === LoginFormEventType.HEIGHT ||
          handler === LoginFormEventType.HEIGHT) &&
        height
      ) {
        readyCount.current += 1;
        if (noOptions || readyCount.current > 1) {
          setFrameHeight(height);
        }
      }

      if (type === LoginFormEventType.DIGITALDATA) {
        options.digitalData = JSON.stringify(window.digitalData);
        sendMessageToIframe();
      }

      // @todo remove - Keeping handler for backwards compatibility
      if (type === LoginFormEventType.CLOSE || handler === LoginFormEventType.CLOSE) {
        onClose?.();
      }

      // @todo remove - Keeping handler for backwards compatibility
      if (type === LoginFormEventType.LOGIN || handler === LoginFormEventType.LOGIN) {
        if (error) {
          onLoginError?.(error);
          onLoginAttempt({ error });
        }
        if (data) {
          onLogin?.(data);
          onLoginAttempt({ data });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const sendMessageToIframe = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(options, frameSrc);
    }
  };

  React.useEffect(() => {
    if (isBrowser) {
      window.addEventListener('message', handleMessage);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <div className="w-full py-2">
      <iframe
        onLoad={sendMessageToIframe}
        ref={iframeRef}
        src={frameSrc}
        title={title}
        height={frameHeight}
        width="100%"
        {...rest}
      />
    </div>
  );
};

LoginIFrame.displayName = 'LoginIFrame';

export default LoginIFrame;
