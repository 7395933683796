import find from 'lodash/find';
import { getIntlNumberLocale } from '@dx-ui/framework-i18n';
import type { TFunction } from 'i18next';
import type { BaseRouter } from 'next/dist/shared/lib/router/router';
import type * as Types from '@dx-ui/gql-types';
import type { CarouselImages } from '@dx-ui/osc-carousel';
import type {
  GetHotelHomePageQuery,
  GetHotelDiningPageQuery,
  GetHotelInfoPageQuery,
  Hotel_Restaurant_Hours_Operation_FragmentFragment,
  GetHotelSpaPageQuery,
} from '../generated/types';
import type { DiningImage } from '../components/cta-cards/cta-card';
import { HotelImageVariantSize } from './constants';
import { env } from '@dx-ui/framework-env';
import type { TRoom } from '../components/rooms-grid/RoomsGrid';
import type {
  TGalleryImageSet,
  TGalleryTabs,
  GalleryImagePrimaryCategory,
} from '../components/property/gallery-grid/GalleryGrid-types';
import { galleryPrimaryCategoryMapper } from '../components/property/gallery-grid/GalleryGrid-types';
import { GraphError, type DXError } from '@dx-ui/types-graphql';
import { eachMonthOfInterval, parseISO } from 'date-fns';

type TVariantImage = {
  xs: string;
  md: string;
  sm: string;
};

function determineVariant(
  width: number | undefined = 0,
  enumTS: typeof HotelImageVariantSize,
  mappedVariantUrlQueryParams: TVariantImage
) {
  const { xs, sm, md } = mappedVariantUrlQueryParams;
  const small = parseInt(xs);
  const medium = parseInt(sm);
  const large = parseInt(md);
  /* using `<=` for including the full width images */
  if (small && width <= small) {
    return enumTS.Xs;
  }
  if (medium && width <= medium) {
    return enumTS.Sm;
  }
  if (large) {
    return enumTS.Md;
  }

  return undefined;
}

export const getHotelImageVariant = (
  variants: Array<Pick<Types.HotelImageVariant, 'size' | 'url'>>,
  width: number | undefined
) => {
  const mappedVariantUrlQueryParams = variants?.reduce(
    (acc: TVariantImage, { size, url }: Pick<Types.HotelImageVariant, 'size' | 'url'>) => {
      if (url) {
        const urlMap = url?.split('?')[1]?.split('&');
        const rwQueryParamValue =
          Array.isArray(urlMap) && urlMap.length > 0
            ? urlMap.find((elem) => elem.includes('rw='))?.split('=')[1]
            : '';
        if (size && rwQueryParamValue) {
          acc[size] = rwQueryParamValue;
        }
      }
      return acc;
    },
    {
      xs: '',
      sm: '',
      md: '',
    }
  );
  const selectedImageVariantData = find(variants, {
    size: determineVariant(width, HotelImageVariantSize, mappedVariantUrlQueryParams),
  });
  return selectedImageVariantData;
};

export const structuredCarouselImages = (
  images: DiningImage,
  width: number | undefined
): CarouselImages[] | [] => {
  if (!images.length) return [];

  return images
    .map((image) => {
      if (image?.variants.length) {
        const url = getHotelImageVariant(image.variants, width)?.url;
        if (url) {
          return {
            url,
            alt: image?.altText,
          };
        }
      }
      return null;
    })
    .filter(Boolean) as CarouselImages[];
};

export const formatDisplayDateWithYear = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  locale: string
) => {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return;
  }

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    calendar: 'gregory',
  }).formatRange(startDate, endDate);
};

export const checkFor200Response = async (url: string): Promise<boolean> => {
  const response = await fetch(url);

  return response.status === 200;
};

export const generateUrl = (
  nextRouter: BaseRouter,
  subpage?: string
): { relativeUrl: string; fullyQualifiedUrl: string } => {
  const {
    asPath,
    locale,
    query: { hotelSlug },
  } = nextRouter;
  const pathWithoutParams = asPath ? asPath.split('?')[0] || '' : '';
  const pathBetweenLocaleAndSubPage = pathWithoutParams.split(`${hotelSlug}/`)[0];
  const withLocale = locale ? `/${locale}` : '';
  const withSubpage = subpage ? `/${subpage}/` : '/';

  return {
    fullyQualifiedUrl: `${env(
      'HILTON_ASSETS_URI'
    )}${withLocale}${pathBetweenLocaleAndSubPage}${hotelSlug}${withSubpage}`,
    relativeUrl: `${withLocale}${pathBetweenLocaleAndSubPage}${hotelSlug}${withSubpage}`,
  };
};

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

export const restaurantNameToUrl = (name: string) =>
  replaceAll(replaceAll(replaceAll(name, "'", ''), '&', 'and'), ' ', '-').toLowerCase();

type Day = {
  index: number;
  day: string;
};

export type ConsolidatedTime = {
  time: string[];
  days: Day[];
  lowestIndex: number;
};

type DayName = keyof NonNullable<
  NonNullable<
    NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
  >['restaurants'][number]['hoursOfOperation']
>;

const compareArrays = (a: string[], b: string[]) =>
  a?.length === b?.length && a.every((element, index) => element === b[index]);

const consolidateOpenCloseToStringFormatter = (time: string) =>
  time.toLowerCase().replace(/(^|-)0/g, '');

const consolidateOpenCloseToString = (open: string, close: string): string =>
  `${consolidateOpenCloseToStringFormatter(open)} - ${consolidateOpenCloseToStringFormatter(
    close
  )}`;

type PoolHoursOfOperation = NonNullable<
  NonNullable<
    NonNullable<GetHotelInfoPageQuery['hotel']>['pools']
  >['poolDetails'][number]['hoursOfOperation']
>;
type PoolHoursOfOperationDay = PoolHoursOfOperation[DayName];
type PoolHoursOfOperationDayHours = NonNullable<PoolHoursOfOperationDay>['hoursOfOperation'];
type NormalizedPoolHoursOfOperationDay = {
  [key in DayName]: PoolHoursOfOperationDayHours;
};

export const getConsolidatedHoursOfOperationPool = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: PoolHoursOfOperation;
  locale: string;
}): ConsolidatedTime[] => {
  if (!hoursOfOperation) {
    return [];
  }
  const result = {} as NormalizedPoolHoursOfOperationDay;
  (Object.keys(hoursOfOperation) as DayName[]).forEach((dayName: DayName) => {
    if (result) {
      result[dayName] = hoursOfOperation[dayName]?.hoursOfOperation ?? [];
      result[dayName] = result[dayName].map((hours: { open: string; close: string }) => {
        return {
          open: hours.open,
          close: hours.close,
        };
      });
    }
  });
  return result ? getConsolidatedHoursOfOperation({ hoursOfOperation: result, locale }) : [];
};

export const getConsolidatedHoursOfOperationRestaurant = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: RestaurantHoursOfOperation | null | undefined;
  locale: string;
}): ConsolidatedTime[] => {
  return hoursOfOperation ? getConsolidatedHoursOfOperation({ hoursOfOperation, locale }) : [];
};

type RestaurantHoursOfOperation = NonNullable<
  NonNullable<
    NonNullable<GetHotelDiningPageQuery['hotel']>['restaurantOverview']
  >['restaurants'][number]['hoursOfOperation']
>;
type RestaurantHoursOfOperationDay = RestaurantHoursOfOperation[DayName];
type RestaurantHoursOfOperationDayHours = RestaurantHoursOfOperationDay[number];

export const getConsolidatedHoursOfOperation = ({
  hoursOfOperation,
  locale,
}: {
  hoursOfOperation: RestaurantHoursOfOperation | null | undefined;
  locale: string;
}): ConsolidatedTime[] => {
  if (hoursOfOperation) {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const reshapedHours = (Object.keys(hoursOfOperation) as DayName[]).reduce(
      (accumulatedDays: ConsolidatedTime[], currentDay: DayName): ConsolidatedTime[] => {
        const dayOfTheWeekOpenCloseTimesFormatted = hoursOfOperation[currentDay]
          .sort(
            (
              hoursObjOne: RestaurantHoursOfOperationDayHours,
              hoursObjTwo: RestaurantHoursOfOperationDayHours
            ) => {
              const objOneFormattedHours = convert12HourTime(hoursObjOne.open.toLowerCase());
              const objTwoFormattedHours = convert12HourTime(hoursObjTwo.open.toLowerCase());
              if (objOneFormattedHours < objTwoFormattedHours) {
                return -1;
              } else if (objOneFormattedHours > objTwoFormattedHours) {
                return 1;
              }
              return 0;
            }
          )
          .map((hours) => {
            const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
              timeStyle: 'short',
              hour12: true,
            };
            try {
              const localizedOpenHours = new Intl.DateTimeFormat(
                locale,
                dateTimeFormatOptions
              ).format(new Date(`${today.toLocaleDateString()} ${hours.open}`));
              const localizedCloseHours = new Intl.DateTimeFormat(
                locale,
                dateTimeFormatOptions
              ).format(new Date(`${today.toLocaleDateString()} ${hours.close}`));
              return consolidateOpenCloseToString(localizedOpenHours, localizedCloseHours);
            } catch {
              return consolidateOpenCloseToString(hours.open, hours.close);
            }
          });

        const currentDayReshaped: ConsolidatedTime = {
          days: [{ day: currentDay, index: 0 }],
          time: dayOfTheWeekOpenCloseTimesFormatted,
          lowestIndex: accumulatedDays.length,
        };

        if (accumulatedDays?.length) {
          const previousAccumulatedDayObj = accumulatedDays[accumulatedDays.length - 1];

          const isPreviousAccumulatedTimeEqualCurrentDayTime = compareArrays(
            previousAccumulatedDayObj?.time || [],
            dayOfTheWeekOpenCloseTimesFormatted
          );

          if (isPreviousAccumulatedTimeEqualCurrentDayTime) {
            const accumulatedDaysMinusLast = accumulatedDays.slice(0, accumulatedDays.length - 1);
            const accumulatedLastDay = accumulatedDays.at(-1) || ({} as ConsolidatedTime);
            const consolidatedMatchingHours: ConsolidatedTime = {
              ...accumulatedLastDay,
              days: [
                ...accumulatedLastDay.days,
                { day: currentDay, index: accumulatedLastDay.days.length },
              ],
            };

            return [...accumulatedDaysMinusLast, consolidatedMatchingHours];
          }
        }

        return [...accumulatedDays, currentDayReshaped];
      },
      [] as ConsolidatedTime[]
    );
    return reshapedHours;
  }

  return [];
};

export type SpaOperatingHours = NonNullable<
  NonNullable<GetHotelSpaPageQuery['hotel']>['spa']
>['operatingHours'];

export const convertOperatingHoursToHoursOfOperation = (operatingHours?: SpaOperatingHours) => {
  return operatingHours?.reduce((acc, operatingHoursWeek) => {
    const days = (Object.keys(operatingHoursWeek) as (keyof typeof operatingHoursWeek)[]).filter(
      (day) => day !== 'headline'
    );
    days.forEach((day) => {
      const dayHours = operatingHoursWeek?.[day];
      if (dayHours[0]) {
        const times = dayHours[0].split(' - ');
        const hours = {
          open: times[0] || '',
          close: times[1] || '',
        };
        if (acc[day]) {
          acc[day].push(hours);
        } else {
          acc[day] = [hours];
        }
      } else {
        if (!acc[day]) {
          acc[day] = [];
        }
      }
    });
    return acc;
  }, {} as NonNullable<Hotel_Restaurant_Hours_Operation_FragmentFragment['hoursOfOperation']>);
};

export const formatDistance = (distance: number, language: string) => {
  const digits = distance % 1 === 0 ? 0 : 2;
  return new Intl.NumberFormat(getIntlNumberLocale(language), {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(distance);
};

export const getDistance = ({
  t,
  distance,
  distanceUnit,
  language,
}: {
  t: TFunction<['hotel-location']>;
  distance?: number | null;
  distanceUnit: Types.HotelDistanceUnit | null | undefined;
  language: string;
}) =>
  distanceUnit
    ? t(`hotel-location:units.${distanceUnit}`, {
        count: distance ?? 0,
        distance: formatDistance(distance ?? 0, language),
      })
    : '';

export const arrayToFormattedString = (arrayOfStrings: string[]): string | null => {
  if (arrayOfStrings.length === 1) {
    return `${arrayOfStrings.join()}.`;
  }

  if (arrayOfStrings.length === 2) {
    return `${arrayOfStrings.join(' and ')}.`;
  }

  if (arrayOfStrings.length > 2) {
    const stringsWithCommas = arrayOfStrings.slice(0, arrayOfStrings.length - 1).join(', ');
    const lastStringWithAnd = arrayOfStrings.slice(-1);

    return `${stringsWithCommas}, and ${lastStringWithAnd}.`;
  }

  return null;
};

export const convert12HourTime = (timeStr: string) => {
  const [rawHours, rawMinutes] = timeStr.split(':') as [string, string];
  const hoursToNum = Number(rawHours);
  const amOrPm = rawMinutes.includes('am') ? 'am' : 'pm';
  const formattedHours = amOrPm === 'pm' && hoursToNum !== 12 ? hoursToNum + 12 : hoursToNum;
  return formattedHours;
};

export const format24hTime = (timeStr: string): { hoursMinutes: string; amOrPm: 'am' | 'pm' } => {
  const [rawHours, rawMinutes] = timeStr.split(':');
  const hoursToNum = Number(rawHours);
  const amOrPm = hoursToNum >= 12 ? 'pm' : 'am';
  const hours = ((hoursToNum + 11) % 12) + 1;
  const minutes = rawMinutes === '00' ? '' : `:${rawMinutes}`;
  return { hoursMinutes: `${hours}${minutes}`, amOrPm };
};

export const getFormattedHours = (operatingHours: Types.HotelPlaceHours[]) => {
  const hours: string[] = [];
  const days = (
    Object.keys(operatingHours[0] || {}) as (keyof (typeof operatingHours)[0])[]
  ).filter((day) => day !== 'headline');

  const convertTime = (timeStr: string) => {
    const [time, modifier] = timeStr.split(' ') as [string, string];
    const hoursMinutes = time.split(':');
    let hours: string | number = hoursMinutes[0] || '';
    const minutes = hoursMinutes[1];

    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  days.forEach((day) => {
    const dayHours = operatingHours?.[0]?.[day];
    if (Array.isArray(dayHours) && dayHours[0]) {
      const times = dayHours[0].split(' - ');
      const isClosed = times[0] === 'Closed';
      const formattedHours =
        !isClosed && times[0] && times[1]
          ? `${convertTime(times[0])} - ${convertTime(times[1])}`
          : 'Closed';

      switch (day) {
        case 'monday':
          hours.push(`Mo ${formattedHours}`);
          break;
        case 'tuesday':
          hours.push(`Tu ${formattedHours}`);
          break;
        case 'wednesday':
          hours.push(`We ${formattedHours}`);
          break;
        case 'thursday':
          hours.push(`Th ${formattedHours}`);
          break;
        case 'friday':
          hours.push(`Fr ${formattedHours}`);
          break;
        case 'saturday':
          hours.push(`Sa ${formattedHours}`);
          break;
        case 'sunday':
          hours.push(`Su ${formattedHours}`);
          break;
        default:
          break;
      }
    }
  });

  return hours;
};

export const getSelfParkingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking']
) => {
  if (parkingPolicies?.hasSelfParking) {
    if (
      (parkingPolicies.selfParkingCharge?.chargeAmount || 0) > 0 ||
      typeof parkingPolicies.selfParkingCharge?.chargeAmount === 'undefined' ||
      parkingPolicies.selfParkingCharge?.chargeAmount === null
    ) {
      if (parkingPolicies.selfParkingCharge?.chargeQuantifier === 'daily') {
        return (
          t('hotel-policies:perDay', {
            chargeAmount: parkingPolicies.selfParkingCharge?.chargeAmountFmt,
          }) || t('hotel-policies:available')
        );
      }
      if (parkingPolicies.selfParkingCharge?.chargeQuantifier === 'hourly') {
        return (
          t('hotel-policies:perHour', {
            chargeAmount: parkingPolicies.selfParkingCharge?.chargeAmountFmt,
          }) || t('hotel-policies:available')
        );
      }
    }
    // else -- selfCharge is zero or undefined
    return t('hotel-policies:complimentary');
  }
  // else -- default case -- hasSelfParking is false
  return t('hotel-policies:notAvailable');
};

export const getEVChargingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking'],
  language: string
) => {
  if (parkingPolicies?.evCharging) {
    if (parkingPolicies.evCharging.hasChargingNearby) {
      return `${t('hotel-policies:nearby')}, ${formatDistance(
        parkingPolicies.evCharging.chargingUnitDistance ?? 0,
        language
      )} ${parkingPolicies.evCharging.chargingUnitDistanceUOM}`;
    }
    if (parkingPolicies.evCharging.hasChargingOnsite) {
      return t('hotel-policies:onSite');
    }
  }
  return t('hotel-policies:notAvailable');
};

export const getValetParkingString = (
  t: TFunction<['hotel-policies']>,
  parkingPolicies: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['policy']>['parking']
) => {
  if (parkingPolicies?.hasValetParking) {
    if (
      (parkingPolicies.valetParkingCharge?.chargeAmount || 0) > 0 ||
      typeof parkingPolicies.valetParkingCharge?.chargeAmount === 'undefined' ||
      parkingPolicies.valetParkingCharge?.chargeAmount === null
    )
      return parkingPolicies.valetParkingCharge?.chargeAmountFmt || t('hotel-policies:available');
    // else -- valetCharge is zero or undefined
    return t('hotel-policies:complimentary');
  }
  // else -- default case -- hasValetParking is false
  return t('hotel-policies:notAvailable');
};

export const searchEngineOptimizedImages = (
  imageUrls: Array<{ url?: string | null }>
): string[] | [] => {
  if (!imageUrls.length || !imageUrls[0]?.url) return [];

  const sixteenByNineRatio =
    '?impolicy=crop&cw=5000&ch=2812&gravity=NorthWest&xposition=0&yposition=0&rw=1200&rh=675';
  const fourByThreeRatio =
    '?impolicy=crop&cw=3750&ch=2812&gravity=NorthWest&xposition=625&yposition=0&rw=1200&rh=900';

  return imageUrls
    .map((imageObj) => [
      `${imageObj.url}${sixteenByNineRatio}`,
      `${imageObj.url}${fourByThreeRatio}`,
    ])
    .flat();
};

export const outWithTheOldInWithTheNew = (
  amenitiesFromPim: NonNullable<GetHotelHomePageQuery['hotel']>['accessibleFeatures'] | undefined,
  replacementText: { newText: string; oldText: string }[]
) =>
  amenitiesFromPim?.map((amenityObj) => {
    const textReplacement = replacementText.find((text) => text.oldText === amenityObj.name);

    if (textReplacement) {
      return { name: textReplacement.newText };
    }

    return amenityObj;
  });

const localeDays = {
  sunday: '2023-01-01T00:00:00',
  monday: '2023-01-02T00:00:00',
  tuesday: '2023-01-03T00:00:00',
  wednesday: '2023-01-04T00:00:00',
  thursday: '2023-01-05T00:00:00',
  friday: '2023-01-06T00:00:00',
  saturday: '2023-01-07T00:00:00',
};

export type LocaleDayNameKey = keyof typeof localeDays;

export function getLocaleDayName(locale: Intl.Locale, dayNameKey: LocaleDayNameKey) {
  return new Date(localeDays[dayNameKey]).toLocaleDateString(locale, { weekday: 'long' });
}

export const filterToUniqueRoomTypes = (allRooms: TRoom[]) =>
  allRooms.reduce((accumulatedRooms, room) => {
    const duplicateRoomType = accumulatedRooms.find(
      (accumulatedRoom) => accumulatedRoom.roomTypeCode === room.roomTypeCode
    );

    if (duplicateRoomType) {
      return [...accumulatedRooms];
    }

    return [...accumulatedRooms, room];
  }, [] as TRoom[]);

export const isObjectEmpty = (obj: object): boolean => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
};

const getGalleryCategoryToImageMapping = (
  primaryCategoryName: Exclude<(typeof GalleryImagePrimaryCategory)[number], 'all'>,
  hotelFeaturesData: string[],
  roomFeaturesData: string[]
) => {
  const categoryDataSet = galleryPrimaryCategoryMapper[primaryCategoryName] || {};
  if (Object.keys(categoryDataSet).length > 0) {
    const isHotelFeaturesMapped =
      hotelFeaturesData.length > 0 && categoryDataSet?.hotelFeatures.length > 0
        ? hotelFeaturesData.some((elem) => categoryDataSet.hotelFeatures.includes(elem))
        : false;
    const isRoomFeaturesMapped =
      roomFeaturesData.length > 0 && categoryDataSet?.roomFeatures.length > 0
        ? roomFeaturesData.some((elem) => categoryDataSet.roomFeatures.includes(elem))
        : false;
    return isHotelFeaturesMapped || isRoomFeaturesMapped;
  }
  return false;
};

export const getGalleryGridTabsData = (
  galleryImages: TGalleryImageSet,
  primaryCategories: typeof GalleryImagePrimaryCategory,
  isResortProperty?: boolean
) => {
  const galleryItemsLength = galleryImages?.length || 0;
  if (galleryItemsLength < 12) {
    return null;
  }
  const tabs = galleryImages.reduce((imageGroup, currImg) => {
    const { hotelFeatures_noTx: hotelFeatures, roomFeatures_noTx: roomFeatures } = currImg;
    primaryCategories.forEach((primaryCategoryName) => {
      const arrElem: TGalleryImageSet = imageGroup[primaryCategoryName] ?? [];
      if (primaryCategoryName !== 'all') {
        if (
          (isResortProperty && primaryCategoryName === 'hotel') ||
          (!isResortProperty && primaryCategoryName === 'resort')
        ) {
          return;
        }
        const isCategoryMapped =
          hotelFeatures && roomFeatures
            ? getGalleryCategoryToImageMapping(primaryCategoryName, hotelFeatures, roomFeatures)
            : false;
        isCategoryMapped && arrElem.push(currImg);
      } else {
        arrElem.push(currImg);
      }
      imageGroup[primaryCategoryName] = arrElem;
    });
    return imageGroup;
  }, {} as NonNullable<TGalleryTabs>);

  const tabContent =
    Object.keys(tabs).length > 0
      ? (Object.fromEntries(
          Object.entries(tabs).filter(([_, v]) => Array.isArray(v) && v.length > 0)
        ) as NonNullable<TGalleryTabs>)
      : null;
  const tabCategories =
    tabContent && Object.keys(tabContent).length > 1
      ? (Object.keys(tabContent) as (typeof GalleryImagePrimaryCategory)[number][])
      : null;

  return tabContent && tabCategories ? { tabCategories, tabContent } : null;
};

export const getTransformedPetFeeText = (petFeeText: string) => {
  if (petFeeText === '$75(1-4n),$125(5+n)2petsMax,dog/cat only')
    return '1-4 night stay $75; 5+ night stay $125; 2 pets max; dog or cat only';
  if (petFeeText === '$50(1-4n),$75(5+n)2petsMax,dog/cat only')
    return '1-4 night stay $50; 5+ night stay $75; 2 pets max; dog or cat only';
  if (petFeeText === '$50(1-4n),$75(5+n) 2petsMax,dog/cat only')
    return '1-4 night stay $50; 5+ night stay $75; 2 pets max; dog or cat only';
  return petFeeText;
};

export const shouldReturnQueryDataFromGraphQLError = (error: DXError) =>
  Boolean(
    error instanceof GraphError &&
      error?.graphQLErrors?.every((gqlError) => gqlError?.code === 404) &&
      error?.data &&
      !isObjectEmpty(error.data)
  );

export const convertCmsImageRatiosToMap = (
  ratios: Pick<Types.CmsPropertyImageRatio, 'url' | 'size'>[]
) =>
  ratios.reduce((result, ratio) => {
    result[ratio.size] = ratio.url ?? '';

    return result;
  }, {} as { [key in Types.CmsPropertyImageRatioName]: Types.CmsPropertyImageRatio['url'] });

export const isPropertyLocatedInUS = (address?: Types.HotelAddress | null) =>
  address?.country === 'US';

/* This is a string manipulation method which will transform the string w.r.t specs mentioned below-- 
    -No Special Characters 
    -Use "_" in place of Spaces 
    -All lower casing

eg. Table to Share => table_to_share
Soups & Salads => soups_salads
Fleming's Joe => flemings_joe 
*/
export const transformTrackingId = (id?: string) => {
  if (!id) {
    return '';
  }

  const transformed_id = id
    .replace(/\-+/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '_')
    .replace(/\_+/g, '_')
    .toLowerCase();

  return transformed_id;
};

export const stringOrBooleanOrNull = (value: string | null): string | boolean | null => {
  if (!value) return null;

  if (value === 'true') return true;

  if (value === 'false') return false;

  return value;
};

export const getMonthRangeBetweenDates = (startDate?: string, endDate?: string): Date[] | [] => {
  if (!startDate || !endDate) {
    return [];
  }
  const formattedStartDate = parseISO(startDate).toISOString();
  const formattedEndDate = parseISO(endDate).toISOString();

  const interval = {
    start: new Date(formattedStartDate),
    end: new Date(formattedEndDate),
  };

  return eachMonthOfInterval(interval);
};
