import cx from 'classnames';
import type * as React from 'react';
import { useTranslation } from 'next-i18next';

export type StepperProps = {
  /**
   * when used in a form, this is the id of the hidden input. (must also include the `inputName` prop)
   */
  inputId?: HTMLInputElement['id'];
  /**
   * when used in a form, this will be the key for the form results. (must also include the `inputId` prop)
   */
  inputName?: HTMLInputElement['name'];
  /**
   * the current state of the Stepper
   */
  value?: number;
  /**
   * upper limit of the value
   */
  max?: number;
  /**
   * lower limit of the value
   */
  min?: number;
  onMinusClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  onPlusClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  /**
   * used for a11y to give a label to the value
   */
  valueLabel?: string;
  /**
   * used for a11y to label the minus button
   */
  minusLabel?: string;
  minusTestId?: string;
  /**
   * used for a11y to label the plus button
   */
  plusLabel?: string;
  plusTestId?: string;
};

type Stepper = StepperProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * Used to increment/decrement a value
 *
 * Use `onPlusClick` and `onMinusClick` to change value one by one.
 */
export const Stepper: React.FC<React.PropsWithChildren<Stepper>> = ({
  className,
  inputId,
  inputName,
  max,
  min,
  onMinusClick,
  onPlusClick,
  value = 0,
  valueLabel = '',
  minusLabel,
  minusTestId,
  plusLabel,
  plusTestId,
  ...rest
}) => {
  const minusDisabled = value === min;
  const plusDisabled = value === max;
  const { t } = useTranslation('osc-stepper');
  return (
    <div className={cx('flex items-center justify-center', className)} {...rest}>
      {!!inputId && !!inputName && (
        <input id={inputId} type="hidden" name={inputName} value={value} />
      )}
      <button
        type="button"
        className="btn btn-primary-text btn-lg size-6 rounded-full"
        onClick={onMinusClick}
        disabled={minusDisabled}
        aria-label={minusLabel || t('minusLabel')}
        data-testid={minusTestId}
      >
        <span className="sr-only">{minusLabel || t('minusLabel')}</span>
        <svg
          role="img"
          aria-hidden
          viewBox="0 0 27 27"
          className={cx('h-6 w-6', {
            'stroke-text-disabled': minusDisabled,
            'stroke-primary': !minusDisabled,
          })}
        >
          <g fill="none" fillRule="evenodd" transform="translate(1 .68)">
            <ellipse cx="12.5" cy="12.724" rx="12.5" ry="12.724" />
            <path strokeLinecap="square" d="M6.5 12.724h12.042M12.5 18.574" />
          </g>
        </svg>
      </button>
      <span className="block w-10 px-1 text-center tabular-nums">
        <span className="sr-only">{valueLabel || value}</span>
        <span aria-hidden>{value}</span>
      </span>
      <button
        type="button"
        className="btn btn-primary-text btn-lg size-6 rounded-full"
        onClick={onPlusClick}
        disabled={plusDisabled}
        aria-label={plusLabel || t('plusLabel')}
        data-testid={plusTestId}
      >
        <span className="sr-only">{plusLabel || t('plusLabel')}</span>
        <svg
          role="img"
          aria-hidden
          viewBox="0 0 27 27"
          className={cx('h-6 w-6', {
            'stroke-text-disabled': plusDisabled,
            'stroke-primary': !plusDisabled,
          })}
        >
          <g fill="none" fillRule="evenodd" transform="translate(1 .68)">
            <ellipse cx="12.5" cy="12.724" rx="12.5" ry="12.724" />
            <path strokeLinecap="square" d="M6.5 12.724h12.042M12.5 18.832V6.574" />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default Stepper;
