import { forwardRef, useImperativeHandle, useRef } from 'react';
import PreSellOpenAlert from '../layout/PreSellOpenAlert';
import cx from 'classnames';
import { HotelAddress } from '@dx-ui/osc-hotel-address';
import { useRenovationFlagTest } from '../../hooks/useRenovationFlagTest';
import { mapCustomClassesToComponent } from '../../helpers/themes/customTheme';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import HotelChip from '../layout/HotelChip';
import SearchWidget from './SearchWidgetContainer';
import type { GetHotelLayoutQuery } from '../../generated/types';
import type { HotelTemplateType, Maybe } from '@dx-ui/gql-types';
import type { LayoutData } from '../../helpers/layout.types';

type TSearchWidgetProps = {
  adultAge: LayoutData['adultAge'];
  ageBasedPricing: LayoutData['ageBasedPricing'];
  adultsOnly: boolean;
  isPartnerBrand: boolean;
  showNumAttendees?: boolean;
  maxNumRooms: number;
  maxOccupants: number;
  isGroupSearch: boolean;
  hideFlexDates: boolean;
  minArrivalDate: string;
  maxArrivalDate: string;
  currencyCode?: Maybe<string> | undefined;
};

export type TSearchContentProps = {
  resEnabled: boolean | null | undefined;
  open: Maybe<boolean> | undefined;
  openDate: Maybe<string> | undefined;
  preOpenMsg: Maybe<string> | undefined;
  resEnabledDate: Maybe<string> | undefined;
  hotelName: Maybe<string> | undefined;
  address: NonNullable<NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['hotel']>['address'];
  isFromWiFi: boolean;
  isNewHotel: boolean;
  traits?: string[];
  templateType: Maybe<HotelTemplateType> | undefined;
  shouldDisplayShopFormOnPage: boolean;
  brandCode: string;
  ctyhocn: string;
  lang: string;
  searchWidgetProps: TSearchWidgetProps;
};

const SearchContentContainer = forwardRef<HTMLDivElement, TSearchContentProps>(
  (
    {
      resEnabled,
      open,
      openDate,
      preOpenMsg,
      resEnabledDate,
      hotelName,
      address,
      isFromWiFi,
      isNewHotel,
      traits = [],
      brandCode,
      ctyhocn,
      lang,
      templateType,
      shouldDisplayShopFormOnPage,
      searchWidgetProps,
    },
    ref
  ) => {
    const {
      adultAge,
      ageBasedPricing,
      adultsOnly,
      isPartnerBrand,
      showNumAttendees,
      maxNumRooms,
      maxOccupants,
      isGroupSearch,
      hideFlexDates,
      minArrivalDate,
      maxArrivalDate,
      currencyCode,
    } = searchWidgetProps;

    useInitializeConductrics({
      traits: [
        `template:${templateType}`,
        `Brand:${brandCode}`,
        `newHotel:${isNewHotel ? 'yes' : 'no'}`,
        `wifi-visit-inspired:${isFromWiFi ? 'yes' : 'no'}`,
        ...traits,
      ],
    });

    const { showRenovationChipInHeader } = useRenovationFlagTest(ctyhocn);
    const wrapperClass = mapCustomClassesToComponent('navShopForm', brandCode);
    const shopFormRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => shopFormRef.current as HTMLDivElement);

    return (
      <>
        {!open ? (
          <PreSellOpenAlert
            brandCode={brandCode}
            isResEnabled={resEnabled ?? false}
            lang={lang}
            openDate={openDate ?? ''}
            preOpenMsg={preOpenMsg ?? ''}
            resEnabledDate={resEnabledDate ?? ''}
          />
        ) : null}
        <div className={cx('bg-bg border-b border-border-alt border-solid', wrapperClass)}>
          <div className="container flex flex-col items-center justify-between text-pretty py-6 xl:flex-row">
            {hotelName ? (
              <div className="flex flex-col items-center justify-center text-center xl:me-4 xl:items-start xl:justify-start xl:text-start">
                <div className="text-balance">
                  <h1
                    className={cx('inline heading-xl', {
                      'lg:heading-xl': hotelName.length >= 60,
                      'lg:heading-2xl': hotelName.length < 60,
                    })}
                  >
                    {hotelName}
                  </h1>
                  {isNewHotel || showRenovationChipInHeader ? (
                    <HotelChip
                      className="ms-1 align-[0.25cap] lg:align-[0.5cap]"
                      variant={isNewHotel ? 'new' : 'renovated'}
                    />
                  ) : null}
                </div>
                {address ? (
                  <div className="pt-3.5 text-center xl:text-balance xl:pt-1 xl:text-start">
                    <HotelAddress
                      hasLink={true}
                      addressLine1={address?.addressLine1 || ''}
                      city={address?.mapCity || ''}
                      countryCode={address?.country || ''}
                      hotelName={hotelName || ''}
                      stateCode={address?.state}
                      singleLineAddress={address.addressFmt || ''}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {resEnabled && shouldDisplayShopFormOnPage ? (
              <div ref={shopFormRef} className="pt-6 xl:pt-0" data-testid="search-widget-container">
                <SearchWidget
                  currencyCode={currencyCode || ''}
                  adultAge={adultAge}
                  ageBasedPricing={ageBasedPricing}
                  brandCode={brandCode}
                  ctyhocn={ctyhocn}
                  isAdultsOnly={adultsOnly}
                  isGroupSearch={isGroupSearch}
                  isOpen={open ?? false}
                  isPartnerBrand={isPartnerBrand}
                  isResEnabled={resEnabled}
                  openDate={openDate ?? ''}
                  resEnabledDate={resEnabledDate ?? ''}
                  showNumAttendees={showNumAttendees ?? false}
                  maxNumRooms={maxNumRooms}
                  maxOccupants={maxOccupants}
                  hideFlexDates={hideFlexDates}
                  minArrivalDate={minArrivalDate}
                  maxArrivalDate={maxArrivalDate}
                />
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
);

SearchContentContainer.displayName = 'SearchContentContainer';
export default SearchContentContainer;
