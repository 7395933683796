import { useRef } from 'react';
import { useStickyShopFormTest } from '../../hooks/use-sticky-shop-form-test';
import SearchContentContainer from './SearchContentContainer';
import { StickySearchWrapper } from './SearchWrapperSticky';
import { SearchWrapperStickyOnScroll } from './SearchWrapperStickyOnScroll';
import type { TSearchContentProps } from './SearchContentContainer';

export const SearchWrapper = (searchWrapperProps: TSearchContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { isStickyShopFormVariant, isShopFormVisibleOnScrollVariant } = useStickyShopFormTest();

  if (isStickyShopFormVariant) {
    return (
      <StickySearchWrapper childRef={contentRef}>
        <SearchContentContainer {...searchWrapperProps} ref={contentRef} />
      </StickySearchWrapper>
    );
  }
  if (isShopFormVisibleOnScrollVariant) {
    return (
      <SearchWrapperStickyOnScroll childRef={contentRef}>
        <SearchContentContainer {...searchWrapperProps} ref={contentRef} />
      </SearchWrapperStickyOnScroll>
    );
  }

  return (
    <div className="inset-0 z-0 m-0 w-full flex-col flex-nowrap p-0">
      <SearchContentContainer {...searchWrapperProps} />
    </div>
  );
};
