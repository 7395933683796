import { useRect } from '@dx-ui/utilities-use-rect';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { getPopupStyles } from '@dx-ui/osc-popup';

export type Tooltip = {
  message: React.ReactNode;
  /** Used to apply custom styles to tooltip's popup */
  popupClassName?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export type TooltipCause = 'escape' | 'focus' | 'initial' | 'mouse';

export const Tooltip: React.FC<React.PropsWithChildren<Tooltip>> = ({
  children,
  className,
  message,
  popupClassName,
  ...rest
}) => {
  const { t } = useTranslation('osc-tooltip');
  const id = React.useId();
  const tipId = `tooltip-${id}`;
  const [currentCause, setCurrentCause] = React.useState<TooltipCause>('initial');
  const [visible, setVisible] = React.useState(false);

  const buttonRef = React.useRef(null);
  const popoverRef = React.useRef(null);
  const popoverRect = useRect({ ref: popoverRef });
  const targetRect = useRect({ ref: buttonRef });
  const styles = getPopupStyles(targetRect, popoverRect);

  const hide = React.useCallback(
    (cause: TooltipCause) => {
      if (cause === 'escape' || currentCause === cause) {
        setVisible(false);
        setCurrentCause('initial');
      }
    },
    [currentCause]
  );
  const show = (cause: TooltipCause) => {
    if (currentCause === 'focus') return;
    setVisible(true);
    setCurrentCause(cause);
  };

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (visible && e.key === 'Escape') {
        hide('escape');
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [hide, visible]);

  return (
    <span className="relative">
      <button
        type="button"
        className={className}
        ref={buttonRef}
        aria-describedby={tipId}
        onBlur={() => hide('focus')}
        onFocus={() => show('focus')}
        onMouseOver={() => show('mouse')}
        onMouseOut={() => hide('mouse')}
        {...rest}
      >
        {children}
      </button>
      <span
        id={tipId}
        ref={popoverRef}
        role="tooltip"
        aria-hidden={!visible}
        className={cx(
          'bg-bg border-border fixed z-50 mt-1 border border-solid p-2 shadow-lg whitespace-nowrap rounded',
          popupClassName,
          { block: visible, hidden: !visible }
        )}
        style={styles}
        aria-label={t('label')}
      >
        {message}
      </span>
    </span>
  );
};

export default Tooltip;
