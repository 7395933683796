import { useMemo } from 'react';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useIsClient } from 'usehooks-ts';
import {
  useGetLanguageSelectorOneLinkConfigQuery,
  useGetLanguageSelectorHotelDataQuery,
} from '@dx-ui/queries-osc-language-selector';
import type { GetLanguageSelectorHotelDataQuery } from '@dx-ui/queries-osc-language-selector';
import { LANGUAGE_CODE_NAMES } from '../osc-language-code-names';
import type { LanguageSelectorPropsBase } from '../osc-language-selector';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import { RuleEngine } from '@dx-shared/rule-engine';

type LanguagesDict = { label: string; value: string; href: string; opensNewTab: boolean }[];

type SupportedLanguages = NonNullable<
  NonNullable<GetLanguageSelectorHotelDataQuery['hotel']>['localization']
>['languageSupport'];

export function useLanguageSelectorLanguages({
  appName,
  ctyhocn,
  removeUrlParams,
  urlOverrides,
}: {
  appName: string;
  ctyhocn: string;
  removeUrlParams?: boolean;
  urlOverrides?: LanguageSelectorPropsBase['urlOverrides'];
}) {
  const { data: featureConfigsData, isFetching: featureConfigsLoading } =
    useGetLanguageSelectorOneLinkConfigQuery();
  const { data: hotelData, isFetching: hotelDataLoading } = useGetLanguageSelectorHotelDataQuery(
    {
      ctyhocn: ctyhocn.toUpperCase(),
    },
    {
      enabled: !!ctyhocn,
    }
  );
  const isClient = useIsClient();
  const loading = !isClient || featureConfigsLoading || hotelDataLoading;

  const oneLinkLanguagesConfig: OneLinkConfig = featureConfigsData?.featureConfigs?.find(
    (fc) => fc.name === 'config-ui-translate-onelink'
  )?.config;

  let oneLinkLanguages: string[] = [];

  // begin old config logic - Deprecated
  const appWhitelist = oneLinkLanguagesConfig?.whitelist?.[appName];
  const languagesObj = oneLinkLanguagesConfig?.languages;
  const languagesToExclude = appWhitelist
    ? (Object.keys(appWhitelist) as (keyof typeof appWhitelist)[]).filter(
        (language) => !appWhitelist?.[language]?.includes(ctyhocn.toUpperCase())
      )
    : [];
  const supportsLanguagesObjConfig = !!appName && !!languagesObj;
  if (supportsLanguagesObjConfig) {
    oneLinkLanguages = Object.entries(languagesObj)
      .filter(
        ([lang, appsArray]) => appsArray?.includes(appName) && !languagesToExclude.includes(lang)
      )
      .map(([lang]) => lang);
  }
  // end old config logic

  // begin new config logic
  const conditions = oneLinkLanguagesConfig?.conditions;
  const supportedLanguages = oneLinkLanguagesConfig?.supportedLanguages;
  const ruleEngine = conditions
    ? new RuleEngine({ name: 'onelink-language-selector', conditions })
    : null;
  const supportsRuleEngineConfig = !!appName && !!conditions && !!supportedLanguages && ruleEngine;
  const url = isBrowser ? window.location.href : '';
  if (supportsRuleEngineConfig) {
    oneLinkLanguages = supportedLanguages.filter((language) =>
      ruleEngine.run({ appName, language, url })
    );
  }
  // end new config logic

  const cmsLanguages = hotelData?.hotel?.localization?.languageSupport
    ? hotelData?.hotel?.localization?.languageSupport.reduce((acc, language) => {
        if (
          !oneLinkLanguages?.includes(getI18nSafeLangCode(language?.code || '')) &&
          getI18nSafeLangCode(language?.code || '') !== 'en'
        ) {
          acc.push({
            ...language,
            code: getI18nSafeLangCode(language?.code || ''),
          });
        }
        return acc;
      }, [] as SupportedLanguages)
    : ([] as SupportedLanguages);

  const cmsFullSiteLanguages = cmsLanguages.filter((lang) => lang?.siteUrl);
  const cmsResOnlyLanguages = cmsLanguages.filter((lang) => lang?.bookingUrl);
  const ENOverride = urlOverrides?.fullSite?.['en'];
  const fullSiteLanguages: LanguagesDict = [
    {
      label: LANGUAGE_CODE_NAMES['en'],
      value: 'en',
      href: (ENOverride || makeLanguageUrl('en', removeUrlParams)) ?? '',
      opensNewTab: !!ENOverride,
    },
    ...oneLinkLanguages.map((lang) => {
      const override = urlOverrides?.fullSite?.[lang as keyof typeof LANGUAGE_CODE_NAMES];
      const hasOverride = !!override;
      return {
        label: LANGUAGE_CODE_NAMES[lang as keyof typeof LANGUAGE_CODE_NAMES],
        value: lang,
        href: (override || makeLanguageUrl(lang, removeUrlParams)) ?? '',
        opensNewTab: hasOverride,
      };
    }),
    ...cmsFullSiteLanguages.map((lang) => ({
      label: LANGUAGE_CODE_NAMES[lang?.code as keyof typeof LANGUAGE_CODE_NAMES] || lang.label,
      value: lang?.code || '',
      href:
        urlOverrides?.fullSite?.[lang?.code as keyof typeof LANGUAGE_CODE_NAMES] ||
        lang?.siteUrl ||
        '',
      opensNewTab: true,
    })),
  ];
  const resOnlyLanguages: LanguagesDict = [
    ...cmsResOnlyLanguages.map((lang) => ({
      label: LANGUAGE_CODE_NAMES[lang?.code as keyof typeof LANGUAGE_CODE_NAMES] || lang.label,
      value: lang?.code || '',
      href:
        urlOverrides?.resOnly?.[lang.code as keyof typeof LANGUAGE_CODE_NAMES] ||
        lang?.bookingUrl ||
        '',
      opensNewTab: true,
    })),
  ];

  const supportedFullSiteLanguages = fullSiteLanguages.map((lang) => lang.value);
  const netNewFullSiteLanguages = Object.keys(urlOverrides?.fullSite || {}).filter(
    (lang) => !supportedFullSiteLanguages.includes(lang)
  );

  if (netNewFullSiteLanguages.length) {
    fullSiteLanguages.push(
      ...netNewFullSiteLanguages.map((lang) => ({
        label: LANGUAGE_CODE_NAMES[lang as keyof typeof LANGUAGE_CODE_NAMES],
        value: lang,
        href: urlOverrides?.fullSite?.[lang as keyof typeof LANGUAGE_CODE_NAMES] || '',
        opensNewTab: true,
      }))
    );
  }

  const supportedResOnlyLanguages = resOnlyLanguages.map((lang) => lang.value);
  const netNewResOnlyLanguages = Object.keys(urlOverrides?.resOnly || {}).filter(
    (lang) =>
      !supportedResOnlyLanguages.includes(lang) && !supportedFullSiteLanguages.includes(lang)
  );

  if (netNewResOnlyLanguages.length) {
    resOnlyLanguages.push(
      ...netNewResOnlyLanguages.map((lang) => ({
        label: LANGUAGE_CODE_NAMES[lang as keyof typeof LANGUAGE_CODE_NAMES],
        value: lang,
        href: urlOverrides?.resOnly?.[lang as keyof typeof LANGUAGE_CODE_NAMES] || '',
        opensNewTab: true,
      }))
    );
  }

  const sortedFullSiteLanguages = sortRtlFirst(
    fullSiteLanguages.sort((a, b) => a.label.localeCompare(b.label))
  );
  const sortedResOnlyLanguages = sortRtlFirst(
    resOnlyLanguages.sort((a, b) => a.label.localeCompare(b.label))
  );
  const allLanguages = useMemo(
    () => [...sortedFullSiteLanguages, ...sortedResOnlyLanguages],
    [sortedFullSiteLanguages, sortedResOnlyLanguages]
  );

  return {
    allLanguages,
    loading,
    sortedFullSiteLanguages,
    sortedResOnlyLanguages,
  };
}

export function getI18nSafeLangCode(code: string) {
  if (code === 'zh_CN') {
    return 'zh-hans';
  }
  if (code === 'zh_HK') {
    return 'zh-hant';
  }
  const safeLangCode = code.split('_')[0];
  return safeLangCode?.toLowerCase() ?? 'en';
}

export function makeLanguageUrl(newLocale: string, removeUrlParams?: boolean) {
  const languageUrl = isBrowser
    ? window.location.href.replace(
        RegExp(`/${window.location.pathname.split('/')[1]}/`),
        `/${newLocale}/`
      )
    : '';
  return removeUrlParams ? languageUrl.split('?')[0] : languageUrl;
}

export function sortRtlFirst(languages: LanguagesDict) {
  const rtlLangs = languages.filter((lang) => isRtl(lang.value));
  const ltrLangs = languages.filter((lang) => !isRtl(lang.value));
  return [...rtlLangs, ...ltrLangs];
}
