import type { ElementRef } from 'react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useLanguageSelectorLanguages } from './hooks/useLanguageSelectorLanguages';
import type { PopupContext } from '@dx-ui/osc-popup';
import { Popup, PopupButton, PopupContent, usePopup } from '@dx-ui/osc-popup';
import { LANGUAGE_CODE_NAMES } from './osc-language-code-names';
import { Link } from '@dx-ui/osc-link';
import classnames from 'classnames';
import { sendReward } from '@dx-ui/framework-conductrics';
import set from 'lodash/set';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import { getKeyDownNavigation } from '@dx-ui/utilities-accessibility';
import { getSafeLanguage } from '@dx-ui/framework-react-query';

type UrlOverride = {
  [locale in keyof typeof LANGUAGE_CODE_NAMES]?: string;
};

export type LanguageSelectorPropsBase = {
  appName: string;
  buttonOptions?: {
    className?: string;
    styles?: React.CSSProperties;
    hoverStyles?: React.CSSProperties;
  };
  ctyhocn?: string;
  labelOptions?: {
    className?: string;
    styles?: React.CSSProperties;
  };
  locale: string;
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
  onLanguageSelection?: (languageValue: string) => void;
  popupOptions?: {
    removeUrlParams?: boolean;
    includeTitles?: boolean;
  };
  urlOverrides?: {
    fullSite?: UrlOverride;
    resOnly?: UrlOverride;
  };
  wrapperClassName?: string;
};

export type LanguageSelectorProps = LanguageSelectorPropsBase & React.ComponentProps<'div'>;

export function LanguageSelector({
  appName = '',
  buttonOptions,
  ctyhocn = '',
  locale = 'en',
  labelOptions,
  popupOptions,
  wrapperClassName,
  urlOverrides,
  theme,
  onLanguageSelection,
  ...rest
}: LanguageSelectorProps) {
  const [selectedLanguage, setSelectedLanguage] = React.useState<{
    code: string;
    href: string;
  }>();
  const [buttonHover, setButtonHover] = React.useState(false);
  const { t } = useTranslation('osc-language-selector');
  const popupButtonRef = useRef<ElementRef<'button'>>(null);
  const linksContainerRef = useRef<ElementRef<'div'>>(null);
  const { loading, allLanguages, sortedFullSiteLanguages, sortedResOnlyLanguages } =
    useLanguageSelectorLanguages({
      appName,
      ctyhocn,
      removeUrlParams: popupOptions?.removeUrlParams,
      urlOverrides,
    });

  useEffect(() => {
    if (!loading && !selectedLanguage?.code) {
      const getSelectedLanguage = (locale: string) => ({
        code: locale,
        href: allLanguages.find((lang) => lang.value === locale)?.href || '',
      });

      setSelectedLanguage(getSelectedLanguage(getSafeLanguage(locale) ?? ''));
    }
  }, [allLanguages, loading, locale, selectedLanguage?.code]);

  const focusFirstLink = React.useCallback(() => {
    linksContainerRef.current?.querySelector('a')?.focus();
  }, []);

  const handleLinkKeyDown = getKeyDownNavigation({
    elements: Array.from(linksContainerRef.current?.querySelectorAll('a') || []),
    initiatingRef: popupButtonRef,
  });

  const trackLinkClick = React.useCallback((language: string) => {
    if (window?.digitalData && window?._satellite) {
      set(window.digitalData, 'action.clickID', 'languageswitch');
      set(window.digitalData, 'page.pageInfo.language', language);
      window._satellite?.track?.('global_click');
    } else {
      console.warn('digitalData or _satellite not found'); // eslint-disable-line no-console
    }
  }, []);

  return (
    <div className={classnames('flex gap-2 text-sm sm:text-base', wrapperClassName)} {...rest}>
      <Popup>
        <PopupContextState>
          {({ isOpen }) => (
            <>
              <div className="flex items-baseline gap-2">
                <span
                  className={classnames('flex font-semibold', labelOptions?.className, {
                    'text-text-inverse': theme === 'dark',
                  })}
                  style={labelOptions?.styles}
                  aria-hidden="true"
                >
                  {t('language')}
                </span>
                {loading ? (
                  <div className="bg-border h-6 w-24 animate-pulse self-center" />
                ) : (
                  <PopupButton
                    ref={popupButtonRef}
                    className={classnames('inline-flex items-center', buttonOptions?.className, {
                      'text-text-inverse': theme === 'dark',
                    })}
                    onClick={() => sendReward('engaged-language-selector')}
                    onArrowDownWhenOpen={focusFirstLink}
                    style={{
                      ...buttonOptions?.styles,
                      ...(buttonHover ? buttonOptions?.hoverStyles : null),
                    }}
                    onMouseEnter={() => setButtonHover(true)}
                    onMouseLeave={() => setButtonHover(false)}
                  >
                    <span className="sr-only">{t('language')}</span>
                    {selectedLanguage?.code
                      ? LANGUAGE_CODE_NAMES[
                          selectedLanguage.code as keyof typeof LANGUAGE_CODE_NAMES
                        ]
                      : null}{' '}
                    <DownArrowIcon
                      className={classnames('size-6 transition-all', {
                        '-scale-y-100': isOpen,
                        'text-text-inverse': theme === 'dark',
                      })}
                    />
                  </PopupButton>
                )}
              </div>
              {loading ? null : (
                <PopupContent popupStyles={{ maxHeight: '80%', overflowY: 'auto' }}>
                  <div
                    ref={linksContainerRef}
                    className="OneLinkKeepLinks flex min-w-[192px] flex-col gap-2"
                  >
                    {sortedFullSiteLanguages.length ? (
                      <div>
                        {popupOptions?.includeTitles ? (
                          <p className="border-border mb-1 border-b pb-2 font-bold">
                            {t('hotelSite')}
                          </p>
                        ) : null}
                        <ul className="divide-border divide-y">
                          {sortedFullSiteLanguages.map((lang) => (
                            <ListItem
                              key={lang.value}
                              label={lang.label}
                              language={lang.value}
                              linkProps={{
                                onClick: () => {
                                  trackLinkClick(lang.value);
                                  onLanguageSelection?.(lang.value);
                                },
                                onKeyDown: handleLinkKeyDown,
                                isNewWindow: lang.opensNewTab,
                                url: lang.href,
                              }}
                            />
                          ))}
                        </ul>
                      </div>
                    ) : null}
                    {sortedResOnlyLanguages.length ? (
                      <div>
                        {popupOptions?.includeTitles ? (
                          <p className="border-border mb-1 border-b pb-2 font-bold">
                            {t('bookingOnly')}
                          </p>
                        ) : null}
                        <ul>
                          {sortedResOnlyLanguages.map((lang) => (
                            <ListItem
                              key={lang.value}
                              label={lang.label}
                              language={lang.value}
                              linkProps={{
                                onClick: () => trackLinkClick(lang.value),
                                onKeyDown: handleLinkKeyDown,
                                isNewWindow: lang.opensNewTab,
                                url: lang.href,
                              }}
                            />
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </PopupContent>
              )}
            </>
          )}
        </PopupContextState>
      </Popup>
    </div>
  );
}

function ListItem({
  label,
  language,
  linkProps,
}: {
  label: string;
  language: string;
  linkProps: React.ComponentProps<typeof Link>;
}) {
  return (
    <li
      key={`popup-${language}`}
      className={classnames('flex py-2', {
        'justify-end': isRtl(language),
      })}
      lang={language}
    >
      <bdi>
        <Link rel="noopener noreferrer" {...linkProps}>
          {label}{' '}
        </Link>
      </bdi>
    </li>
  );
}

function PopupContextState({ children }: { children: (args0: PopupContext) => React.ReactNode }) {
  const context = usePopup();
  return children(context);
}

function DownArrowIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 31 31" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="transparent" fillRule="evenodd">
        <g transform="translate(1, 1)" strokeWidth="2" stroke="currentColor">
          <polyline strokeLinecap="round" points="9,12 14.5,18 20,12" />
        </g>
      </g>
    </svg>
  );
}

export default LanguageSelector;
