export const getIsReducedMotion = () =>
  typeof window !== 'undefined' && typeof window.matchMedia === 'function'
    ? window.matchMedia('(prefers-reduced-motion: reduce)').matches
    : true;

export const getKeyDownNavigation =
  <T extends HTMLElement[]>({
    elements,
    initiatingRef,
    onPreviousFocus,
    onNextFocus,
  }: {
    elements: T;
    initiatingRef?: React.MutableRefObject<HTMLElement | null>;
    onPreviousFocus?: (focusElement: T[number] | null) => void;
    onNextFocus?: (focusElement: T[number] | null) => void;
  }) =>
  (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => {
    const focusPrevious = (el: EventTarget | null) => {
      const currentIndex = elements.findIndex((elements) => elements === el);
      if (currentIndex === 0) {
        initiatingRef?.current?.focus();
        return;
      }
      const element = elements.at(currentIndex - 1) || null;
      element?.focus();
      onPreviousFocus?.(element);
    };
    const focusNext = (el: EventTarget | null) => {
      const currentIndex = elements.findIndex((elements) => elements === el);
      const element = elements.at(currentIndex + 1) || null;
      element?.focus();
      onNextFocus?.(element);
    };
    const isArrowUp = e.key === 'ArrowUp';
    const isArrowDown = e.key === 'ArrowDown';
    const isArrowLeft = e.key === 'ArrowLeft';
    const isArrowRight = e.key === 'ArrowRight';
    if (isArrowUp || isArrowLeft) {
      e.preventDefault();
      focusPrevious(e.target);
    }
    if (isArrowDown || isArrowRight) {
      e.preventDefault();
      focusNext(e.target);
    }
  };
