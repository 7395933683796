import { getBaseNavLinksFromEnv, useProgramMember } from '../header.utilities';
import { useAuth } from '@dx-ui/framework-auth-provider';
import type { UserLinks } from '../types';
import { useRouter } from 'next/router';

export const useActiveUserLinks = (): UserLinks => {
  const { guestInfo, isAuthenticated } = useAuth();
  const { locale, asPath } = useRouter();

  const navLinks = getBaseNavLinksFromEnv(locale || 'en', isAuthenticated);

  const member = useProgramMember({
    programAccountId: guestInfo?.hhonors?.programAccountSummary?.[0]?.accountId,
  });

  const isSMBMember = Boolean(member?.role && ['admin', 'owner', 'employee'].includes(member.role));
  const isHGVMax = Boolean(guestInfo?.hhonors?.isOwnerHGVNew) || asPath.includes('hgv-max');

  const isTMTPTeamMember =
    guestInfo?.hhonors?.isOwner ||
    guestInfo?.hhonors?.isTeamMember ||
    guestInfo?.hhonors?.isOwnerHGV;

  const isTMTPNonTeamMember =
    guestInfo?.hhonors?.isTeamMemberSpouse ||
    guestInfo?.hhonors?.isFamilyAndFriends ||
    guestInfo?.hhonors?.isLongTenure10 ||
    guestInfo?.hhonors?.isLongTenure20;

  return {
    ...navLinks,
    smbMemberLink: isSMBMember ? navLinks?.smbMemberLink : undefined,
    friendsAndFamilyLink: isTMTPTeamMember ? navLinks?.friendsAndFamilyLink : undefined,
    goHiltonLink: isTMTPNonTeamMember || isTMTPTeamMember ? navLinks?.goHiltonLink : undefined,
    hgvMaxLink: isHGVMax ? navLinks?.hgvMaxLink : undefined,
  };
};
