import type { Dialog as DialogType } from '@dx-ui/osc-dialog';
import { ActionDialog } from '@dx-ui/osc-dialog';
import type { FormDataStructure, FormProps } from '@dx-ui/osc-form';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { Rooms as RoomsType } from './rooms';
import { Rooms } from './rooms';
import { type FieldValues, FormProvider, useForm } from 'react-hook-form';
import type { ShopFormRoom } from './types';

type RoomsModal = Pick<FormProps, 'useFormProps'> &
  RoomsType &
  DialogType & { onSubmit: (data: FieldValues) => void };

const RoomsModal: React.FC<React.PropsWithChildren<RoomsModal>> = ({
  ageRange,
  agesRequired,
  ariaLabel,
  useFormProps,
  maxRooms,
  occupancyLimitMessage,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('osc-rooms');
  const memoizedFormProps = React.useMemo(() => useFormProps, [useFormProps]);
  const confirmButtonRef = React.useRef<HTMLButtonElement>(null);

  const methods = useForm<FormDataStructure>({
    mode: 'onTouched',
    criteriaMode: 'all',
    ...memoizedFormProps,
  });
  const { setValue, clearErrors, getValues } = methods;
  const rooms: ShopFormRoom[] = getValues().rooms;
  const isAnyRoomHaveChildrenOccupants = rooms?.some((room) => room?.children?.length);

  const removeKids = () => {
    clearErrors();
    rooms?.forEach((_room, index) => {
      setValue(`rooms.${index}.children`, []);
    });
    confirmButtonRef?.current?.focus();
  };

  return (
    <FormProvider {...methods}>
      <ActionDialog
        {...rest}
        size="xl"
        ariaLabel={ariaLabel || t('occupancy.ageBasedTitle')}
        contentClassName="overflow-hidden pb-16 md:px-8"
        data-testid="rooms-modal"
        onDismiss={removeKids}
        onConfirm={() => onSubmit?.(getValues())}
        preventCloseOnDismiss={true}
        buttonOptions={{
          cancel: {
            label: t('occupancy.ageBasedRemove'),
            disabled: !isAnyRoomHaveChildrenOccupants,
          },
          confirm: { ref: confirmButtonRef },
        }}
      >
        <div className="px-8 pt-8">
          <div className="mb-6 space-y-2 text-center">
            <h2 className="text-lg font-bold">{t('occupancy.ageBasedTitle')}</h2>
            <p>{t('occupancy.ageBasedDescription')}</p>
          </div>

          <Rooms
            ageRange={ageRange}
            maxRooms={maxRooms}
            agesRequired={agesRequired}
            occupancyLimitMessage={occupancyLimitMessage}
          />
        </div>
      </ActionDialog>
    </FormProvider>
  );
};

export { RoomsModal };
export default RoomsModal;
