import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Spinner } from '@dx-ui/osc-spinner';

type FormHelpMessage = {
  loading?: boolean;
  message?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const FormHelpMessage = React.forwardRef<HTMLDivElement, FormHelpMessage>(
  ({ className, message, loading, ...rest }, ref) => {
    const { t } = useTranslation('osc-form');
    const hasContent = !!message || loading;

    return (
      <div
        ref={ref}
        className={cx('text-text-alt items-center space-x-2 text-sm', className, {
          hidden: !hasContent,
          'flex pt-1': hasContent,
        })}
        {...rest}
      >
        {loading && (
          <>
            <Spinner size="sm" />
            <p className={cx({ 'sr-only': !!message })}>{t('loading')}</p>
          </>
        )}
        {message && <p>{message}</p>}
      </div>
    );
  }
);

FormHelpMessage.displayName = 'FormHelpMessage';

export { FormHelpMessage };
export default FormHelpMessage;
