import type { FormInputBase } from '@dx-ui/osc-form';
import type { ComboboxInputProps } from '@reach/combobox';
import { ComboboxOption } from '@reach/combobox';
import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { GeocodeCoordinateQuery } from '../gql/types';

type LocationHotelsNearMeOption = ComboboxInputProps &
  FormInputBase<Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onSelect'>>;

const LocationHotelsNearMeOption = ({
  geocodeData,
}: {
  geocodeData?: GeocodeCoordinateQuery['geocode'];
  showNoResultsFound?: boolean;
}) => {
  const { t } = useTranslation('osc-location');
  const userCity = geocodeData?.match?.address?.city || '';

  let stateCountry = geocodeData?.match?.address?.stateName;
  if (stateCountry)
    stateCountry += geocodeData?.match?.address?.country
      ? `, ${geocodeData?.match?.address?.country}`
      : `${geocodeData?.match?.address?.country}`;
  else
    stateCountry += geocodeData?.match?.address?.country
      ? ` ${geocodeData?.match?.address?.country}`
      : ``;

  return geocodeData && userCity ? (
    <div>
      <div className="flex pb-0 pt-1 ltr:pl-2 ltr:text-left rtl:pr-2 ">
        <span className="text-text-alt">{t('optionsHeader.hotelsNearBy')}</span>
      </div>
      <ComboboxOption
        key={userCity}
        value={userCity}
        className="hover:bg-bg-primary highlighted:bg-bg-alt cursor-pointer px-4 py-2"
        aria-setsize={1}
        aria-posinset={1}
      >
        <div className="flex min-w-[226px]">
          <div className="flex justify-center" aria-hidden>
            <img
              className="size-6 object-contain"
              alt="location icon"
              src="/modules/assets/img/common/icon_location@2x.png"
            />
          </div>
          <div className="flex flex-col ltr:pl-2 ltr:text-left rtl:pr-2">
            <span className="text-base">{userCity}</span>
            <span className="text-text-alt text-xs">{stateCountry}</span>
          </div>
        </div>
      </ComboboxOption>
    </div>
  ) : null;
};

export { LocationHotelsNearMeOption };
export default LocationHotelsNearMeOption;
