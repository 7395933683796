import {
  addMonths,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfMonth,
  subMonths,
  addDays,
} from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { ControlBack, ControlNext } from '@dx-ui/osc-controls';
import type { CalendarMonth as CalendarMonthType } from './calendar.month';
import { CalendarMonth } from './calendar.month';
import { sendReward } from '@dx-ui/framework-conductrics';

const INPUT_FORMAT = 'yyyy-MM-dd';

export type Calendar = {
  /**
   * Used for giving unique ids based on the form in which it is used
   */
  formId?: string;
  /**
   * When used in a form this will be the key of the `day` i.e. `arrivalDate`
   */
  dayId?: string;
  /**
   * When used in a form this will be the key of the `endDay` i.e. `departureDate`
   */
  endDayId?: string;
  /**
   * Whether or not to allow selecting the same day for both `day` and `endDay`
   */
  allowSameDay?: boolean;
  onEndDayChange?: (day?: Date) => void;
  /**
   * the a11y instructions for the calendar
   */
  instructions?: string;
  /**
   * used in a live region to possibly give context to the selected date(s)
   */
  message?: string;
  /** to display the content of a month tab selector component before the calendar dates view */
  MonthDateSelectorComponent?: ({
    selectedCalendarDate,
    onMonthUpdate,
  }: {
    selectedCalendarDate: Date;
    onMonthUpdate: (d: Date) => void;
  }) => React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement> &
  Pick<
    CalendarMonthType,
    | 'day'
    | 'endDay'
    | 'onDayChange'
    | 'maxDays'
    | 'locale'
    | 'dayLabel'
    | 'allowPrevious'
    | 'today'
    | 'highlightedDates'
    | 'enabledDates'
  >;

/**
 * A component to display calendars for selection of a date or date range. can be used in conjunction with a popup or inline.
 */
export const Calendar: React.FC<React.PropsWithChildren<Calendar>> = ({
  allowSameDay,
  allowPrevious,
  children,
  locale,
  day,
  endDay,
  maxDays,
  onDayChange,
  onEndDayChange,
  dayLabel,
  instructions,
  message,
  formId,
  dayId,
  endDayId,
  highlightedDates,
  enabledDates,
  today = new Date(),
  MonthDateSelectorComponent,
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const month0Ref = React.useRef<HTMLDivElement>(null);
  const month1Ref = React.useRef<HTMLDivElement>(null);
  const [focusedDay, setFocusedDay] = React.useState<number>();
  const previousMonthRef = React.useRef<HTMLButtonElement>(null);
  const nextMonthRef = React.useRef<HTMLButtonElement>(null);
  const [month, setMonth] = React.useState(startOfMonth(day || today));
  const [t] = useTranslation('osc-calendar');
  const nextDay = day ? addDays(day, 1) : day;
  const prevDisabled = allowPrevious ? false : isSameMonth(month, today);
  const selectedDateRef = React.useRef(day || today);

  const onChangeDay = (d?: Date) => {
    if (onEndDayChange && d) {
      if (day && !endDay) {
        if (allowSameDay) {
          /**
           * Handle cases where `allowSameDay` is true
           */
          if (isSameDay(d, day) || isAfter(d, day)) {
            onEndDayChange(d);
          } else if (isBefore(d, day)) {
            onDayChange(d);
          }
        } else {
          /**
           * Handle cases where `allowSameDay` is false
           */
          // eslint-disable-next-line no-lonely-if
          if (isAfter(d, day)) {
            onEndDayChange(d);
          } else if (isBefore(d, day)) {
            onDayChange(d);
          }
        }
      } else {
        onDayChange(d);
        onEndDayChange(undefined);
      }
    } else {
      onDayChange(d);
    }
    if (MonthDateSelectorComponent && d) {
      selectedDateRef.current = d;
      sendReward('g-date-selector-interaction-total');
    }
  };

  const getDayLabel = (d: Date) => {
    if (dayLabel) {
      if (onEndDayChange && d) {
        if (day && !endDay) {
          if (allowSameDay) {
            if (isSameDay(d, day) || isAfter(d, day)) {
              return dayLabel(d, false);
            }
            if (isBefore(d, day)) {
              return dayLabel(d, true);
            }
          } else {
            if (isAfter(d, day)) {
              return dayLabel(d, false);
            }
            if (isBefore(d, day)) {
              return dayLabel(d, true);
            }
          }
        } else {
          return dayLabel(d, true);
        }
      } else {
        return dayLabel(d, true);
      }
      return dayLabel(d);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return d.toLocaleDateString(locale, { dateStyle: 'full' });
  };

  const onMonthChange = (d: Date) => {
    setMonth(d);
  };

  const onFocusedDayChange = (d: number, newD?: number, monthNumber?: number) => {
    if (newD) {
      setFocusedDay(newD);
      const monthInFocus = monthNumber === 0 ? month : addMonths(month, 1);
      if (!isSameMonth(monthInFocus, newD)) {
        if (newD > d) {
          if (monthNumber === 0 && !!month1Ref.current?.offsetParent) return;
          onMonthChange(addMonths(month, 1));
        }
        if (newD < d && monthNumber === 0) {
          onMonthChange(subMonths(month, 1));
        }
      }
    }
  };

  React.useEffect(() => {
    if (ref.current) {
      requestAnimationFrame(() => {
        const element = ref.current?.querySelector<HTMLButtonElement>(`#day-${focusedDay}`);
        if (element) {
          element.focus();
        }
      });
    }
  }, [focusedDay, ref]);

  return (
    <div ref={ref} className="w-full max-w-6xl" data-testid="calendar-container">
      {!!day && (
        <>
          <input
            type="hidden"
            name={dayId}
            id={`${formId}-${dayId}`}
            value={day && format(day, INPUT_FORMAT)}
          />
          <input
            type="hidden"
            name={endDayId}
            id={`${formId}-${endDayId}`}
            value={
              endDay ? format(endDay, INPUT_FORMAT) : day && format(addDays(day, 1), INPUT_FORMAT)
            }
          />
        </>
      )}
      {MonthDateSelectorComponent ? (
        <MonthDateSelectorComponent
          selectedCalendarDate={selectedDateRef.current}
          onMonthUpdate={onMonthChange}
        />
      ) : null}
      <div
        className={cx('flex justify-between', {
          hidden: !!MonthDateSelectorComponent,
        })}
      >
        <ControlBack
          onClick={() => onMonthChange(subMonths(month, 1))}
          ref={previousMonthRef}
          disabled={prevDisabled}
          label={t('previousMonth')}
        />
        <ControlNext
          onClick={() => onMonthChange(addMonths(month, 1))}
          ref={nextMonthRef}
          label={t('nextMonth')}
        />
      </div>

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className="max-w-6xl" role="application" aria-label={instructions} {...rest}>
        <div aria-live="polite" className="sr-only">
          {message}
        </div>
        <div className="flex w-full ltr:space-x-6">
          <CalendarMonth
            ref={month0Ref}
            onDayChange={onChangeDay}
            locale={locale}
            day={day}
            endDay={endDay || nextDay}
            month={month}
            maxDays={maxDays}
            focusedDay={focusedDay}
            onFocusedDayChange={(d, newD) => onFocusedDayChange(d, newD, 0)}
            isVisibleMonth
            dayLabel={getDayLabel}
            allowPrevious={allowPrevious}
            today={today}
            highlightedDates={highlightedDates}
            enabledDates={enabledDates}
          />
          <CalendarMonth
            ref={month1Ref}
            onDayChange={onChangeDay}
            locale={locale}
            day={day}
            endDay={endDay || nextDay}
            maxDays={maxDays}
            month={addMonths(month, 1)}
            className="hidden md:block"
            focusedDay={focusedDay}
            onFocusedDayChange={(d, newD) => onFocusedDayChange(d, newD, 1)}
            dayLabel={getDayLabel}
            allowPrevious={allowPrevious}
            today={today}
            highlightedDates={highlightedDates}
            enabledDates={enabledDates}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
