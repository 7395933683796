import type { HeaderProps } from './types';
import { useTranslation } from 'next-i18next';

export const Logo: React.FC<Pick<HeaderProps, 'brand' | 'theme'>> = ({ brand, theme }) => {
  const { t } = useTranslation('osc-header');
  const altText =
    brand.code === 'WW'
      ? t('hiltonForTheStay')
      : brand.code === 'OU'
      ? `${t('hiltonForTheStay')}, ${brand.name}`
      : brand.code === 'LX'
      ? `${t('hiltonForTheStay')}, SLH`
      : brand.name;

  return (
    <img
      src={
        theme === 'dark'
          ? `/modules/assets/svgs/logos/${brand.code}_inverted.svg`
          : `/modules/assets/svgs/logos/${brand.code}.svg`
      }
      //Logo for worldwide has marketing text. So only when WW is passed use custom alt text override
      alt={altText}
      className="header-logo"
    />
  );
};

export const HeaderLogoLink: React.FC<
  Pick<HeaderProps, 'brand' | 'theme' | 'heading'> & Pick<React.ComponentProps<'a'>, 'className'>
> = ({ brand, className, theme, heading }) => {
  const { t } = useTranslation('osc-header');

  const Wrapper = heading ? 'h1' : 'span';

  return (
    <Wrapper aria-label={heading ? heading : undefined} className={className}>
      <a
        data-testid="brand-logo-link"
        href={brand.url}
        className="brand-ou:lg:w-72 brand-lx:lg:w-44 relative block h-14 w-28 overflow-hidden"
        {...(!!brand.onClick && { onClick: brand.onClick })}
        {...(!!brand.target && { target: brand.target })}
        {...(brand?.target === '_blank' && { rel: 'noreferrer' })}
      >
        <Logo theme={theme} brand={brand} />
        <span className="sr-only">{brand?.target === '_blank' ? `, ${t('newTab')}` : ''}</span>
      </a>
    </Wrapper>
  );
};
